import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "../loader.service";
import {COLLECTIONS_CONFIG} from "../../config/api";
import {Router} from "@angular/router";


@Injectable({
    providedIn: 'root'
})
export class CollectionsService  {
    private config = COLLECTIONS_CONFIG;

    constructor(private http: HttpClient,
                private router: Router,
                private loaderService: LoaderService) {
    }

    getData(from, to) {
        this.loaderService.show();
        return this.http.post<any>(this.config.data, {from, to});
    }

    getSummaryData(from, to) {
        this.loaderService.show();
        return this.http.post<any>(this.config.dataSummary, {from, to});
    }

    onSettle() {
        this.loaderService.show();
        return this.http.post<any>(this.config.settle, {});
    }

    onSettleVerify(from, to, pin) {
        this.loaderService.show();
        return this.http.post<any>(this.config.settleVerify, {from, to, pin});
    }

    onSummarySettleVerify(weeks, pin) {
        this.loaderService.show();
        return this.http.post<any>(this.config.summarySettleVerify, {weeks, pin});
    }

    onSettleConfirm(from, to) {
        this.loaderService.show();
        return this.http.post<any>(this.config.settleConfirm, {from, to});
    }

    onSummarySettleConfirm(weeks) {
        this.loaderService.show();
        return this.http.post<any>(this.config.summarySettleConfirm, {weeks});
    }

    getDetails(from, to) {
        this.loaderService.show();
        return this.http.post<any>(this.config.details, {from, to});
    }
}
