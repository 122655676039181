import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NEW_USER_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class NewUserService {
    private config = NEW_USER_CONFIG;

    constructor(private http: HttpClient) {

    }

    sell(phone, amount, usdc_amount, gameProviderId) {
        return this.http.post<any>(this.config.save, {phone, amount, usdc_amount, gameProviderId});
    }
}
