<div class="login">
    <div class="login__inner">
        <div class="login__header">
            <a [routerLink]="['/login']" class="login__back" style="top: 120%;">
                <span class="visually-hidden">Back</span>
            </a>

            <!--<img class="login__logo" [src]="partnerLogo" alt="">-->

        </div>
        <!--<div *ngIf="isPhoneClassActive()" class="login__title">{{partnerName}} POS</div>-->
        <div *ngIf="isPhoneClassActive()" class="login__title">POS</div>
        <div class="login__forms">
            <div *ngIf="isPhoneClassActive()" class="text-center login-tab">
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="login__form">
                    <div class="login__item">
                        <input formControlName="name"
                               placeholder="Name*"
                               [type]="'text'"
                               class="login-input"
                               autocomplete="off">

                    </div>
                    <div class="login__item">
                        <input formControlName="email"
                               placeholder="Email*"
                               [type]="'text'"
                               class="login-input"
                               autocomplete="off">

                    </div>
                    <div class="login__item">
                        <input mask="(000) 000-0000"
                               formControlName="phone"
                               placeholder="Phone"
                               [type]="'text'"
                               class="login-input"
                               autocomplete="off">

                    </div>
                    <div class="login__item">
                        <input formControlName="zip"
                               mask="00000"
                               placeholder="Zip Code*"
                               [type]="'text'"
                               class="login-input"
                               autocomplete="off">

                    </div>
                    <div class="login__item">
                        <textarea formControlName="requests"
                               rows="3"
                               placeholder="Requests"
                               class="login-input"
                                  autocomplete="off"></textarea>

                    </div>

                    <div class="form-btn-submit">
                        <button class="button snake-btn is-notouched" [disabled]="!registerForm.valid">
                            SUBMIT
                            <svg><rect></rect></svg>
                        </button>

                    </div>
                </form>
            </div>

<!--            <div *ngIf="isPinClassActive()" class="login-tab">-->
<!--                <div class="login-content">-->
<!--                    <p class="text t-light login__text">Please verify your phone.</p>-->
<!--                    <p class="text t-light login__text">A code was sent to the number you provided which ends in -{{phoneLastFour}}.</p>-->
<!--                </div>-->
<!--                <form [formGroup]="pinForm" (ngSubmit)="onSubmit()" class="login-page__form">-->
<!--                    <div class="login__items" formArrayName="digits">-->
<!--                        <input *ngFor="let field of pinForm.get('digits')['controls']; let i = index" #inputs [maxLength]="1" [formControlName]="i" (keydown)="check(i, field, $event)"-->
<!--                               class="login-input-small">-->
<!--                    </div>-->

<!--                    <button (click)="resendSms()" type="button" class="text t-light login-text login__resend">Resend</button>-->

<!--                    <div class="text-center form-btn-submit">-->
<!--                        <button class="button" type="submit" [disabled]="!pinForm.valid">-->
<!--                            CONFIRM-->
<!--                        </button>-->
<!--                    </div>-->
<!--                </form>-->
<!--            </div>-->
        </div>
    </div>
</div>

<!--<app-policy (policyState)="policyState($event)" [policy]="policy" ></app-policy>-->
