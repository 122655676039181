import { EXISTING_USER_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ExistingUserService = /** @class */ (function () {
    function ExistingUserService(http) {
        this.http = http;
        this.config = EXISTING_USER_CONFIG;
    }
    ExistingUserService.prototype.getUsers = function (page, phone, accountId) {
        return this.http.post(this.config.getUsers, { page: page, phone: phone, accountId: accountId });
    };
    ExistingUserService.prototype.findUserByRiverpayAccountId = function (phone, accountId) {
        return this.http.post(this.config.findUserByRiverpayAccountId, { phone: phone, accountId: accountId });
    };
    ExistingUserService.prototype.getUserInfo = function (id, gameProviderId) {
        return this.http.post(this.config.userInfo, { id: id, gameProviderId: gameProviderId });
    };
    ExistingUserService.prototype.getUserCredit = function (id, page, gameProviderId) {
        return this.http.post(this.config.userCredit, { id: id, page: page, gameProviderId: gameProviderId });
    };
    ExistingUserService.prototype.getUserDebit = function (id, page, gameProviderId) {
        return this.http.post(this.config.userDebit, { id: id, page: page, gameProviderId: gameProviderId });
    };
    ExistingUserService.prototype.getUserCrypto = function (id, page, gameProviderId) {
        return this.http.post(this.config.userCrypto, { id: id, page: page, gameProviderId: gameProviderId });
    };
    ExistingUserService.prototype.freeze = function (id) {
        return this.http.post(this.config.freeze, { id: id });
    };
    ExistingUserService.prototype.delete = function (id) {
        return this.http.post(this.config.delete, { id: id });
    };
    ExistingUserService.prototype.sell = function (id, amount, usdc_amount, gameProviderId) {
        return this.http.post(this.config.sell, { id: id, amount: amount, usdc_amount: usdc_amount, gameProviderId: gameProviderId });
    };
    ExistingUserService.prototype.buy = function (id, amount, gameProviderId) {
        return this.http.post(this.config.buy, { id: id, amount: amount, gameProviderId: gameProviderId });
    };
    ExistingUserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExistingUserService_Factory() { return new ExistingUserService(i0.ɵɵinject(i1.HttpClient)); }, token: ExistingUserService, providedIn: "root" });
    return ExistingUserService;
}());
export { ExistingUserService };
