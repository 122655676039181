import { OnInit } from '@angular/core';
import * as i0 from "@angular/core";
var ConclusionComponent = /** @class */ (function () {
    function ConclusionComponent() {
    }
    ConclusionComponent.prototype.ngOnInit = function () {
    };
    ConclusionComponent.prototype.isNegativeNumber = function (amount) {
        return amount < 0;
    };
    ConclusionComponent.prototype.getAbs = function (amount) {
        return Math.abs(amount);
    };
    ConclusionComponent.prototype.isString = function (value) {
        return typeof value === 'string' && value === 'NA';
    };
    ConclusionComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConclusionComponent_Factory() { return new ConclusionComponent(); }, token: ConclusionComponent, providedIn: "root" });
    return ConclusionComponent;
}());
export { ConclusionComponent };
