import { PHONE_MASK_BY_ID, DEFAULT_PHONE_MASK } from "../config/country_phone_mask";
import * as i0 from "@angular/core";
import * as i1 from "../modules/auth/_services/authentication.service";
var PhoneMaskService = /** @class */ (function () {
    function PhoneMaskService(authenticationService) {
        this.authenticationService = authenticationService;
        this.PHONE_MASK_CONFIG = PHONE_MASK_BY_ID;
        this.DEFAULT_PHONE_MASK = DEFAULT_PHONE_MASK;
    }
    PhoneMaskService.prototype.setPhoneMask = function () {
        if (this.PHONE_MASK_CONFIG[this.authenticationService.getPointCountryId()]) {
            return this.PHONE_MASK_CONFIG[this.authenticationService.getPointCountryId()];
        }
        else {
            return this.DEFAULT_PHONE_MASK;
        }
    };
    PhoneMaskService.prototype.getPhoneMaskByCountryId = function (id) {
        if (this.PHONE_MASK_CONFIG[id]) {
            return this.PHONE_MASK_CONFIG[id];
        }
        else {
            return this.DEFAULT_PHONE_MASK;
        }
    };
    PhoneMaskService.prototype.getDefaultPhoneMask = function () {
        return this.DEFAULT_PHONE_MASK;
    };
    PhoneMaskService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PhoneMaskService_Factory() { return new PhoneMaskService(i0.ɵɵinject(i1.AuthenticationService)); }, token: PhoneMaskService, providedIn: "root" });
    return PhoneMaskService;
}());
export { PhoneMaskService };
