import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonComponent} from "./button/button.component";
import {HeaderComponent} from "./header/header.component";
import {PolicyComponent} from "./policy/policy.component";

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {ActivityComponent} from "./activity/activity.component";
import {PopupComponent} from "./popup/popup.component";
import {ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {LoaderComponent} from "./loader/loader.component";
import {TransactionsPopupComponent} from "./transactions-popup/transactions-popup.component";
import {Summary} from "@angular/compiler";
import {SummaryComponent} from "./summary/summary.component";
import {MoreInfoComponent} from "./moreInfo/more-info.component";
import {ConclusionComponent} from "./conclusion/conclusion.component";
import {PinPopupComponent} from "./pin-popup/pin-popup.component";
import {PaymentsInfoPopupComponent} from "./payments-info-popup/payments-info-popup.component";
import {FinancialInfoItemComponent} from "./financial-info-item/financial-info-item.component";
// import {DatepickerCustomHeaderExample, ExampleHeader} from "./datapicker/datapicker-header";
// import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material";
// import {DatepickerCustomHeaderExample, ExampleHeader} from "./datapicker-header/datapicker-header";
// import {DemoMaterialModule} from "../material.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};


@NgModule({
    declarations: [
        ButtonComponent,
        HeaderComponent,
        PolicyComponent,
        ActivityComponent,
        PopupComponent,
        LoaderComponent,
        TransactionsPopupComponent,
        SummaryComponent,
        MoreInfoComponent,
        ConclusionComponent,
        PinPopupComponent,
        PaymentsInfoPopupComponent,
        FinancialInfoItemComponent,

    ],
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
    ],
    exports: [
        ButtonComponent,
        HeaderComponent,
        PolicyComponent,
        ActivityComponent,
        PopupComponent,
        LoaderComponent,
        TransactionsPopupComponent,
        SummaryComponent,
        MoreInfoComponent,
        ConclusionComponent,
        PinPopupComponent,
        PaymentsInfoPopupComponent,
        FinancialInfoItemComponent,
    ]
})
export class SharedModule { }
