import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    AfterViewInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren, HostListener, Injectable
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {LoginService} from "../../service/login.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {MenuService} from "../../service/menu.service";
import {routerTransition, slideToRight} from "../../animations/router.animations";
import {LoaderService} from "../../service/loader.service";
import {RememberUserService} from "../../service/rememberUser.service";
import {TEMPLATE_VERSION} from "../../config/template_version";
import {PartnerService} from "../../service/partner.service";
import {PARTNERS_INFO} from "../../config/project";


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': ''}
})
@Injectable({providedIn: 'root'})
export class LoginComponent implements OnInit {
    userForm: FormGroup;

    TEMPLATE_VERSION = TEMPLATE_VERSION;

    isRiverPay = this.partnerService.isRiverPay();
    partnerLogo = PARTNERS_INFO.default.logo;
    partnerName = PARTNERS_INFO.default.name;

    // phoneFormActive = true;
    // phoneLastFour = '0005';
    // numOfDigits = 6;
    //
    // @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    @HostListener('click', ['$event'])
    clickInside(event) {
        const snakeBtns = document.querySelectorAll('.snake-btn');
        snakeBtns.forEach(el => {
            el.classList.remove('is-notouched');
        });
    }

    constructor(
        private formBuilder: FormBuilder,
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private loginService: LoginService,
        private menuService: MenuService,
        private router: Router,
        private auth:AuthenticationService,
        private loaderService: LoaderService,
        private partnerService: PartnerService,
        private rememberUserService: RememberUserService,
        private popupComponent: PopupComponent) {

    }
    ngOnInit() {
        this.auth.logout();

        this.userForm = this.formBuilder.group({
            password: [null, Validators.required],
            user_name: [null, Validators.required],
            remember_user: [false]
        });

        this.userForm.controls.password.setValue('');

        if (localStorage.remember_user !== "false") {
            this.userForm.controls.remember_user.setValue(true);
            this.userForm.controls.user_name.setValue(localStorage.usrname);
        } else {
            this.userForm.controls.remember_user.setValue(false);
            //this.userForm.controls.password.setValue('');
            this.userForm.controls.user_name.setValue('');
        }

        this.rememberUserService.checkSaveUser(this.userForm.controls.user_name, this.userForm.controls.password);

        this.checkRiverPay();
    }

    checkRiverPay() {
        if (this.isRiverPay) {
            this.partnerLogo = PARTNERS_INFO.riverPay.logo;
            this.partnerName = PARTNERS_INFO.riverPay.name;
        }
    }

    onSubmit(){
        if (this.userForm.invalid) {
            return;
        }

        let userName = this.userForm.controls.user_name.value;
        let password = this.userForm.controls.password.value;
        this.loaderService.show();

        this.authenticationService.login(userName,password).subscribe(data => {
            this.loaderService.hide();
            try {
                if (data.status) {
                    if (this.userForm.controls.remember_user.value) {
                        // save username and password
                        localStorage.usrname = userName;
                    } else {
                        localStorage.usrname = '';
                    }
                    localStorage.remember_user = this.userForm.controls.remember_user.value;

                    this.rememberUserService.setSaveUser(userName, password);

                   this.checkTemplate(data);
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }
        });

    }

    checkTemplate(data) {
        //data.template = this.TEMPLATE_VERSION.version2;
        if (data.template && data.template === this.TEMPLATE_VERSION.version2) {
            localStorage.template = data.template;
            this.router.navigate(['/home/sell/existing-user']);
            return;
        }

        localStorage.removeItem('template');

        this.router.navigateByUrl('/home/sell', {skipLocationChange: true}).then(() => {
            this.router.navigate(['/home/sell']);
        });
    }

    onRememberUser(e) {
        const checked = e.target.checked;
        this.userForm.controls.remember_user.setValue(!!checked);
    }

}

