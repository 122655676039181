import * as i0 from "@angular/core";
var PaginationScrollService = /** @class */ (function () {
    // public visible = new BehaviorSubject(false);
    function PaginationScrollService() {
    }
    PaginationScrollService.prototype.resetPagination = function (data) {
        data.prevPagination = 0;
        data.previewPagination = 1;
        data.lastScrollTop = 0;
        data.items = [];
        data.loading = false;
        data.countLastAddedItems = 0;
        data.lastAddedItem = 0;
    };
    PaginationScrollService.prototype.initScrolledData = function (paginationDataName, settings) {
        paginationDataName.items = settings.itemsList;
        paginationDataName.limit = settings.scrollLimit ? settings.scrollLimit : 40;
        paginationDataName.countTotalItems = settings.total;
        paginationDataName.lastAddedItem = paginationDataName.items.length;
        paginationDataName.dataRichEnd = +paginationDataName.countTotalItems === +paginationDataName.lastAddedItem;
        paginationDataName.canScroll = paginationDataName.items.length >= paginationDataName.limit && !paginationDataName.dataRichEnd;
        return paginationDataName;
    };
    PaginationScrollService.prototype.onScroll = function (e, settings, loaderFunction) {
        // console.log(!settings.loading, settings.canScroll);
        // console.log('services', settings);
        if (!settings.loading && settings.canScroll) {
            var elems = document.querySelectorAll(settings.selector);
            var rect = elems[elems.length - 1] !== undefined ? elems[elems.length - 1].getBoundingClientRect() : undefined;
            var rectStart = void 0;
            var documentRect = document.querySelector(settings.selectorParent).getBoundingClientRect();
            if (settings.prevPagination > 0) {
                rectStart = elems[0] !== undefined ? elems[Math.round(settings.limit * .8)].getBoundingClientRect() : undefined;
            }
            else {
                rectStart = undefined;
            }
            var st = e.target.pageYOffset || e.target.scrollTop;
            if (st > settings.lastScrollTop) {
                if (rect !== undefined && rect.bottom < documentRect.bottom + rect.height * Math.round(settings.limit * .2) && !settings.dataRichEnd) {
                    settings.loading = true;
                    var loadingPage = settings.previewPagination + 1;
                    loaderFunction(loadingPage, 'end', settings);
                }
            }
            else {
                if (e.target.scrollTop <= rect.height * 15 && settings.prevPagination > 0) {
                    settings.loading = true;
                    var loadingPage = (settings.countLastAddedItems < settings.limit && settings.countLastAddedItems !== 0) ? settings.prevPagination - 1 : settings.prevPagination;
                    loaderFunction(loadingPage, 'start', settings);
                }
            }
            settings.lastScrollTop = st <= 0 ? 0 : st;
        }
    };
    // ADDING NEW DATA
    PaginationScrollService.prototype.dataAddingLogic = function (page, step, data, settings) {
        // PAGINATION FIXES
        // settings.countTotalItems = data.total;
        if (data.status) {
            // ADD PAGE TO THE END
            if (step === 'end') {
                // UPDATE SETTINGS
                settings.lastAddedItem += data[settings.itemsListIfNoResult].length;
                settings.previewPagination += 1;
                if (+settings.lastAddedItem === +settings.countTotalItems) {
                    settings.dataRichEnd = true;
                }
                // DATA RICH END LOGIC
                if (settings.dataRichEnd) {
                    settings.countLastAddedItems = data[settings.itemsListIfNoResult].length;
                    if (settings.items.length > (settings.limit * 2)) {
                        settings.prevPagination += 1;
                    }
                }
                else {
                    // REMOVE PAGE FROM BEGINING
                    if (settings.items.length > (settings.limit * 2)) {
                        settings.items.splice(0, data[settings.itemsListIfNoResult].length);
                        settings.prevPagination += 1;
                    }
                }
                // ADD PAGE TO THE END
                for (var i = 0; i < data[settings.itemsListIfNoResult].length; i++) {
                    settings.items.push(data[settings.itemsListIfNoResult][i]);
                }
                ;
            }
            // ADD PAGE TO BEGIN
            if ((step === 'start') && settings.prevPagination) {
                var additionalRemovedItems = 0;
                if (settings.countLastAddedItems < settings.limit && settings.countLastAddedItems !== 0) {
                    additionalRemovedItems = settings.countLastAddedItems;
                    settings.prevPagination -= 2;
                    settings.previewPagination -= 2;
                }
                else {
                    settings.prevPagination -= 1;
                    settings.previewPagination -= 1;
                }
                // REMOVE PAGE FROM END
                settings.items.splice(settings.items.length - data[settings.itemsListIfNoResult].length - additionalRemovedItems, data[settings.itemsListIfNoResult].length + additionalRemovedItems);
                settings.lastAddedItem -= data[settings.itemsListIfNoResult].length + additionalRemovedItems;
                // ADD PAGE TO BEGINING
                for (var i = data[settings.itemsListIfNoResult].length - 1; i >= 0; i--) {
                    settings.items.unshift(data[settings.itemsListIfNoResult][i]);
                }
                settings.dataRichEnd = false;
            }
            settings.loading = false;
        }
    };
    PaginationScrollService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaginationScrollService_Factory() { return new PaginationScrollService(); }, token: PaginationScrollService, providedIn: "root" });
    return PaginationScrollService;
}());
export { PaginationScrollService };
