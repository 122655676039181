import {
    Component,
    ElementRef,
    EventEmitter, HostListener,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {first, map} from "rxjs/operators";
import {PopupComponent} from "../../shared/popup/popup.component";
import {routerTransition, slideToLeft} from "../../animations/router.animations";
import {LoaderService} from "../../service/loader.service";
import {PARTNERS_INFO} from "../../config/project";
import {PartnerService} from "../../service/partner.service";



@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class SignUpComponent implements OnInit {
    policy = false;

    registerForm: FormGroup;
    // pinForm: FormGroup;

    phoneFormActive = true;

    isRiverPay = this.partnerService.isRiverPay();
    partnerLogo = PARTNERS_INFO.default.logo;
    partnerName = PARTNERS_INFO.default.name;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    // IF we need rest on changing page
    @HostListener('click', ['$event'])
    clickInside(event) {
        const snakeBtns = document.querySelectorAll('.snake-btn');
        snakeBtns.forEach(el => {
            el.classList.remove('is-notouched');
        })
    }

    constructor(
        private formBuilder: FormBuilder,
        private fb: FormBuilder,
        private loaderService: LoaderService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private partnerService: PartnerService,
        private popupComponent: PopupComponent) {

    }
    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            name: [null, Validators.required],
            email: [null, Validators.required],
            phone: [null],
            zip: [null, Validators.required],
            requests: [null]
        });

        const snakeBtns = document.querySelectorAll('.snake-btn');
        snakeBtns.forEach(el => {
            el.classList.add('is-notouched');
        });

        this.checkRiverPay();
    }

    checkRiverPay() {
        if (this.isRiverPay) {
            this.partnerLogo = PARTNERS_INFO.riverPay.logo;
            this.partnerName = PARTNERS_INFO.riverPay.name;
        }
    }

    onSubmit() {
        if (this.registerForm.invalid) {
            return;
        }

        let name = this.registerForm.controls.name.value;
        let email = this.registerForm.controls.email.value;
        let phone = this.registerForm.controls.phone.value;
        let zip = this.registerForm.controls.zip.value;
        let requests = this.registerForm.controls.requests.value;
        this.loaderService.show();

        this.authenticationService.register(name,email,phone,zip,requests).subscribe(data => {
            this.loaderService.hide();

            if(data.status){
                this.router.navigate(['/']);

                this.popupComponent.showPopupSuccess({text:data.message});
                // success message - data.message
            }else{
                this.popupComponent.showPopupError({text:data.message})
                // error message
            }
        });
    }

    isPhoneClassActive(){
        if(this.phoneFormActive){
            return true;
        }

        return false;
    }

}
