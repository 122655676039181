import {Injectable} from '@angular/core';
import {PHONE_MASK_BY_ID,DEFAULT_PHONE_MASK} from "../config/country_phone_mask";
import {AuthenticationService} from "../modules/auth/_services/authentication.service";

@Injectable({
    providedIn: 'root'
})
export class PhoneMaskService {
    PHONE_MASK_CONFIG = PHONE_MASK_BY_ID;
    DEFAULT_PHONE_MASK = DEFAULT_PHONE_MASK;

    constructor(private authenticationService: AuthenticationService) {
    }

    setPhoneMask() {
        if(this.PHONE_MASK_CONFIG[this.authenticationService.getPointCountryId()]){
            return this.PHONE_MASK_CONFIG[this.authenticationService.getPointCountryId()];
        }else{
            return this.DEFAULT_PHONE_MASK;
        }

    }

    getPhoneMaskByCountryId(id){
        if(this.PHONE_MASK_CONFIG[id]){
            return this.PHONE_MASK_CONFIG[id];
        }else{
            return this.DEFAULT_PHONE_MASK;
        }
    }

    getDefaultPhoneMask(){
        return this.DEFAULT_PHONE_MASK;
    }
}
