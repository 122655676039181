import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatesService {

  constructor() {}

  getDate(moment) {
    // console.log(moment);
    const date = new Date(moment);
    return `${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`

    // const date = new Date(moment).toUTCString();
    //
    // console.log(date, new Date(moment), String(new Date(moment).getUTCMonth()+1), String(new Date(moment).getMonth()+1))
    //
    // return `${new Date(Date.parse(moment)).getUTCFullYear()}-${String(new Date(moment).getMonth()+1).padStart(2, '0')}-${String(new Date(Date.parse(date)).getDate()).padStart(2, '0')}`
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  getSunday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff+6));
  }
}
