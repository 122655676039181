import {Component, EventEmitter, Input, OnInit, Output,Injectable} from '@angular/core';

@Component({
    selector: 'app-conclusion',
    templateUrl: './conclusion.component.html',
    styleUrls: ['./conclusion.component.scss']
})

@Injectable({providedIn: 'root'})
export class ConclusionComponent implements OnInit {

    @Input() data;
    @Input() type?;

    constructor() {
    }

    ngOnInit() {

    }

    isNegativeNumber(amount) {
        return amount < 0;
    }

    getAbs(amount) {
        return Math.abs(amount);
    }

    isString(value) {
       return typeof value === 'string' && value === 'NA';
    }
}
