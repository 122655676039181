import { OnInit } from '@angular/core';
import * as i0 from "@angular/core";
var SummaryComponent = /** @class */ (function () {
    function SummaryComponent() {
    }
    SummaryComponent.prototype.ngOnInit = function () {
    };
    SummaryComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SummaryComponent_Factory() { return new SummaryComponent(); }, token: SummaryComponent, providedIn: "root" });
    return SummaryComponent;
}());
export { SummaryComponent };
