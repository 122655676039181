import {Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {MenuService} from "../../service/menu.service";
import {ReportTransactionsItem} from "../../models/reportTransactions/reportTransactions";
import {opacity, slideToLeft} from "../../animations/router.animations";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PIN_POPUP_TYPE} from "./pin-popup-config";

@Component({
    selector: 'app-pin-popup',
    templateUrl: './pin-popup.component.html',
    styleUrls: ['./pin-popup.component.scss'],
    animations: [opacity],
    host: {'[@enterAnimation]': ''}
})
export class PinPopupComponent implements OnInit {
    @Input() data;
    @Input() showPopup;
    @Input() popupType;

    @Output() close = new EventEmitter();

    pinForm: FormGroup;
    numOfDigits = 4;

    PIN_POPUP_TYPE = PIN_POPUP_TYPE;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    constructor(private formBuilder: FormBuilder,) {
        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([])
        });
    }

    ngOnInit() {
        for (let i = 0; i< this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null))
        }
    }

    closePopup() {
        this.close.emit();
        this.resetPinInput();
    }

    onPinSubmit() {
        if (this.pinForm.invalid) {
            return;
        }

        let pin = this.pinForm.controls.digits.value;
        pin = pin.join('');

        this.close.emit({
            status: true,
            type: this.PIN_POPUP_TYPE.pin,
            pin: pin
        });
    }

    onSubmit() {
      this.close.emit({
        status: true,
        type: this.PIN_POPUP_TYPE.confirm,
      });
      this.resetPinInput();
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }
        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        }
        else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }

    resetPinInput() {
      (this.pinForm.get('digits') as FormArray).clear();
      for (let i = 0; i< this.numOfDigits; i++) {
        (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
      }
    }
}
