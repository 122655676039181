import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    DateAdapter,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import {SharedModule} from '../shared/shared.module';
import {PagesModule} from './pages-routing.module';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { MainComponent } from './main/main.component';

import {LoginComponent} from "./login/login.component";
import {SignUpComponent} from "./signup/signup.component";

import {MatSliderModule} from '@angular/material/slider';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FocusMonitor} from "@angular/cdk/a11y";
import {Platform} from "@angular/cdk/platform";

import { NgxCurrencyModule } from "ngx-currency";
import {HomeComponent} from "./home/home.component";
import {SellComponent} from "./sell/sell.component";
import {ReportsComponent} from "./reports/reports.component";
import {NewUserComponent} from "./sell/new-user/new-user.component";
import {ExistingUserComponent} from "./sell/existing-user/existing-user.component";
import {ExistingUserInfoComponent} from "./sell/existing-user-info/existing-user-info.component";
import {DatepickerCustomHeaderExample, ExampleHeader} from "../shared/datapicker-header/datapicker-header";
import {DemoMaterialModule} from "../material.module";
import {InstallComponent} from "./install/install.component";
import {GamesProviderComponent} from "./games-provider/games-provider.component";
import {CollectionsComponent} from "./collections/collections.component";
import {NgxDateRangeModule} from "ngx-daterange";
import {CustomDateAdapter} from "../adapters/custom-date-adapter";
import {CollectionsSummaryComponent} from "./collections-summary/collections-summary.component";
import {TermsAndConditionsComponent} from "./terms-and-conditions/terms-and-conditions.component";
import {FillRequestFormComponent} from "./fill-request-form/fill-request-form.component";
import {DirectDepositComponent} from "./direct-deposit/direct-deposit.component";




@NgModule({
    declarations: [
        MainComponent,
        LoginComponent,
        SignUpComponent,

        HomeComponent,

        SellComponent,
        NewUserComponent,
        ExistingUserComponent,
        ExistingUserInfoComponent,

        ReportsComponent,
        DirectDepositComponent,
        DatepickerCustomHeaderExample,
        ExampleHeader,
        InstallComponent,

        GamesProviderComponent,
        CollectionsComponent,
        CollectionsSummaryComponent,
      TermsAndConditionsComponent,
      FillRequestFormComponent,

    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PagesModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        MatDialogModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        SweetAlert2Module.forRoot(),
        MatSliderModule,
        // ChartsModule,
        NgxCurrencyModule,
        // CarouselModule,
        // SwiperModule,
        // SlickCarouselModule
        DemoMaterialModule,
      NgxDateRangeModule,
    ],
    providers: [
        FocusMonitor,
        Platform,
        {provide: DateAdapter, useClass: CustomDateAdapter }
        // {
        //     provide: SWIPER_CONFIG,
        //     useValue: DEFAULT_SWIPER_CONFIG
        // }
    ],
    exports: [
        LoginComponent,
        SignUpComponent,
        SellComponent,
        DatepickerCustomHeaderExample,
        ExampleHeader,
        InstallComponent
    ],
    entryComponents: [
        LoginComponent,
        SignUpComponent,
        SellComponent,
        DatepickerCustomHeaderExample, ExampleHeader
    ]
})
export class ChatQuizModule { }
