import { GAME_VENDORS_RIVER } from "../../../config/gameVendors";
import * as i0 from "@angular/core";
import * as i1 from "../../../modules/auth/_services/authentication.service";
var VendorService = /** @class */ (function () {
    function VendorService(authenticationService) {
        this.authenticationService = authenticationService;
        this.gameVendorsRiver = GAME_VENDORS_RIVER;
    }
    VendorService.prototype.isShowReportsLogs = function () {
        if (this.authenticationService.getGameVendorShortName() == this.gameVendorsRiver) {
            return true;
        }
        return false;
    };
    VendorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VendorService_Factory() { return new VendorService(i0.ɵɵinject(i1.AuthenticationService)); }, token: VendorService, providedIn: "root" });
    return VendorService;
}());
export { VendorService };
