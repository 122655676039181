import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {EXISTING_USER_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ExistingUserService {
    private config = EXISTING_USER_CONFIG;

    constructor(private http: HttpClient) {

    }

    getUsers(page, phone, accountId) {
        return this.http.post<any>(this.config.getUsers, {page, phone, accountId});
    }

    findUserByRiverpayAccountId(phone, accountId) {
        return this.http.post<any>(this.config.findUserByRiverpayAccountId, {phone, accountId});
    }

    getUserInfo(id, gameProviderId) {
        return this.http.post<any>(this.config.userInfo, {id, gameProviderId});
    }

    getUserCredit(id, page, gameProviderId) {
        return this.http.post<any>(this.config.userCredit, {id, page, gameProviderId});
    }

    getUserDebit(id, page, gameProviderId) {
        return this.http.post<any>(this.config.userDebit, {id, page, gameProviderId});
    }

    getUserCrypto(id, page, gameProviderId) {
        return this.http.post<any>(this.config.userCrypto, {id, page, gameProviderId});
    }

    freeze(id) {
        return this.http.post<any>(this.config.freeze, {id});
    }

    delete(id) {
        return this.http.post<any>(this.config.delete, {id});
    }

    sell(id, amount, usdc_amount, gameProviderId) {
        return this.http.post<any>(this.config.sell, {id, amount, usdc_amount, gameProviderId});
    }

    buy(id, amount, gameProviderId) {
        return this.http.post<any>(this.config.buy, {id, amount, gameProviderId});
    }

}
