import {Injectable} from '@angular/core';
import {SELL_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "../loader.service";

@Injectable({
  providedIn: 'root'
})
export class SellService {
  private config = SELL_CONFIG;

  constructor(private http: HttpClient,
              private loaderService: LoaderService) {

  }

  getLimit() {
    return this.http.post<any>(this.config.getLimit, {});
  }

  requestRefill() {
    this.loaderService.show();
    return this.http.post<any>(this.config.requestRefill, {});
  }
}
