import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var BlurMenuService = /** @class */ (function () {
    function BlurMenuService() {
        this.menuBlurred = new BehaviorSubject(false);
    }
    BlurMenuService.prototype.blur = function () {
        this.menuBlurred.next(true);
        document.body.classList.add('is-hidden');
    };
    BlurMenuService.prototype.focus = function () {
        this.menuBlurred.next(false);
        document.body.classList.remove('is-hidden');
    };
    BlurMenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BlurMenuService_Factory() { return new BlurMenuService(); }, token: BlurMenuService, providedIn: "root" });
    return BlurMenuService;
}());
export { BlurMenuService };
