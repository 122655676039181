/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./sell.component";
import * as i5 from "../../service/sell/sell.service";
import * as i6 from "../../shared/popup/popup.component";
import * as i7 from "../../service/loader.service";
var styles_SellComponent = [i0.styles];
var RenderType_SellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SellComponent, data: { "animation": [{ type: 7, name: "slideToRight", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(-100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(-100%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_SellComponent as RenderType_SellComponent };
export function View_SellComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "sell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 21, "div", [["class", "sell__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 20, "div", [["class", "navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "a", [["class", "navigation__button button snake-btn is-notouched"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵted(-1, null, [" NEW USER "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "a", [["class", "navigation__button navigation__button--secondary button snake-btn is-notouched"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(12, 1), (_l()(), i1.ɵted(-1, null, [" EXISTING USER "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 7, "div", [["class", "navigation__bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "navigation__info"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, ["Your POS Balance is ", ""])), i1.ɵppd(19, 3), (_l()(), i1.ɵeld(20, 0, null, null, 3, "button", [["class", "navigation__button navigation__button--default button snake-btn is-notouched"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.requestRefill() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" REQUEST REFILL "])), (_l()(), i1.ɵeld(22, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = _ck(_v, 6, 0, "/home/sell/games-providers/new-user"); _ck(_v, 5, 0, currVal_2); var currVal_5 = _ck(_v, 12, 0, "/home/sell/existing-user"); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 11).target; var currVal_4 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵunv(_v, 18, 0, _ck(_v, 19, 0, i1.ɵnov(_v, 0), _co.balance, "USD", "symbol")); _ck(_v, 18, 0, currVal_6); }); }
export function View_SellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sell", [], [[40, "@slideToRight", 0]], null, null, View_SellComponent_0, RenderType_SellComponent)), i1.ɵdid(1, 114688, null, 0, i4.SellComponent, [i5.SellService, i6.PopupComponent, i7.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var SellComponentNgFactory = i1.ɵccf("app-sell", i4.SellComponent, View_SellComponent_Host_0, {}, {}, []);
export { SellComponentNgFactory as SellComponentNgFactory };
