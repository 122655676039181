<div class="transactions-bg"></div>
<div class="transactions-wrapper">
    <button class="transactions-wrapper__close" (click)="closePopup()">
        <span class="visually-hidden">Close</span>
    </button>
    <div class="transactions-wrapper__inner">
        <table class="transactions">
            <thead class="transactions__head">
            <tr>
                <th>DATE</th>
                <th>EVENT</th>
                <th>VALUE</th>
                <th>BALANCE</th>
                <th></th>
            </tr>
            </thead>


            <tbody class="transactions__body">
            <tr *ngFor="let transaction of transactionsResult" class="transactions__row">
                <td>{{transaction.date | date:'MM/dd/yyyy hh:mm:ss'}}</td>
<!--                <td>{{transaction.date | date: 'MM-dd-yyyy'}}</td>-->
                <td>{{transaction.event}}</td>
                <td>{{transaction.value | currency}}</td>
                <td>{{transaction.balance | currency}}</td>
                <td>
                    <button (click)="reverseHandler(transaction.id)"
                            [disabled]="!transaction.reverse"
                            class="transactions__btn"
                            [class.is-disabled]="!transaction.reverse">
                        REVERSE
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
