import {Component, EventEmitter, Input, OnInit, Output,Injectable} from '@angular/core';
import {BlurMenuService} from "../../service/blurMenu.service";

@Component({
    selector: 'app-more-info',
    templateUrl: './more-info.component.html',
    styleUrls: ['./more-info.component.scss']
})

@Injectable({providedIn: 'root'})
export class MoreInfoComponent implements OnInit {

    @Input() items;

    @Input() showPopup;
    @Output() close = new EventEmitter();

    constructor() {
    }

    ngOnInit() {

    }

    onClosePopup() {
        this.close.emit();
    }

    getTotal(array, key) {
        let total = 0;

        array.forEach(el => {
           total += +el[key];
        });

        return total;
    }

}
