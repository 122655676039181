import {Inject, Injectable, NgZone} from '@angular/core';
import {GAME_VENDORS,GAME_VENDORS_RIVER} from "../../../config/gameVendors";
import {AuthenticationService} from "../../../modules/auth/_services/authentication.service";

@Injectable({
    providedIn: 'root'
})
export class VendorService {
    private gameVendorsRiver = GAME_VENDORS_RIVER;

    constructor(private authenticationService: AuthenticationService) {

    }

    isShowReportsLogs() {
        if(this.authenticationService.getGameVendorShortName() == this.gameVendorsRiver){
            return true;
        }
        return false;
    }

}
