import { COLLECTIONS_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../loader.service";
var CollectionsService = /** @class */ (function () {
    function CollectionsService(http, router, loaderService) {
        this.http = http;
        this.router = router;
        this.loaderService = loaderService;
        this.config = COLLECTIONS_CONFIG;
    }
    CollectionsService.prototype.getData = function (from, to) {
        this.loaderService.show();
        return this.http.post(this.config.data, { from: from, to: to });
    };
    CollectionsService.prototype.getSummaryData = function (from, to) {
        this.loaderService.show();
        return this.http.post(this.config.dataSummary, { from: from, to: to });
    };
    CollectionsService.prototype.onSettle = function () {
        this.loaderService.show();
        return this.http.post(this.config.settle, {});
    };
    CollectionsService.prototype.onSettleVerify = function (from, to, pin) {
        this.loaderService.show();
        return this.http.post(this.config.settleVerify, { from: from, to: to, pin: pin });
    };
    CollectionsService.prototype.onSummarySettleVerify = function (weeks, pin) {
        this.loaderService.show();
        return this.http.post(this.config.summarySettleVerify, { weeks: weeks, pin: pin });
    };
    CollectionsService.prototype.onSettleConfirm = function (from, to) {
        this.loaderService.show();
        return this.http.post(this.config.settleConfirm, { from: from, to: to });
    };
    CollectionsService.prototype.onSummarySettleConfirm = function (weeks) {
        this.loaderService.show();
        return this.http.post(this.config.summarySettleConfirm, { weeks: weeks });
    };
    CollectionsService.prototype.getDetails = function (from, to) {
        this.loaderService.show();
        return this.http.post(this.config.details, { from: from, to: to });
    };
    CollectionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CollectionsService_Factory() { return new CollectionsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.LoaderService)); }, token: CollectionsService, providedIn: "root" });
    return CollectionsService;
}());
export { CollectionsService };
