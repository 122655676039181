import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "../loader.service";
import {GAMES_PROVIDER_CONFIG} from "../../config/api";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class GamesProviderService  {
    private config = GAMES_PROVIDER_CONFIG;
    checkedGameProvider = new BehaviorSubject(null);

    constructor(private http: HttpClient,
                private router: Router,
                private loaderService: LoaderService) {
    }

    checkIfNewUser() {
        return this.router.url.includes('new-user');
    }

    getGamesProviders() {
        this.loaderService.show();
        let post = this.http.post<any>(this.config.gamesProviders, {});
        return post;
    }

    setGameProvider(gameProvider) {
        this.checkedGameProvider.next(gameProvider);
    }

    getGameProviderName() {
        if (this.checkedGameProvider) {
            if(this.checkedGameProvider.value){
                return this.checkedGameProvider.value.name;
            }

        }

        return '';
    }
}
