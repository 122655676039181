<div class="page page--secondary page--scrolled" [class.is-ios]="isIos()" (scroll)="pageScroll($event, searchBtn)">
    <div class="page__inner">
        <a *ngIf="!isTemplate2()" [routerLink]="['/home/sell']" class="page__back">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">EXISTING USER</h1>
        <div class="page__form">
            <form [formGroup]="existingUserForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <input [mask]=phoneMask
                           formControlName="phone"
                           placeholder="Phone Number"
                           [type]="'text'"
                           (focus)="searchInputFocus('phone')"
                           class="form-simple__input"
                           autocomplete="off">
                    <button type="button" class="form-simple__reset" *ngIf="inputHasValue('phone')" (click)="resetInput('phone')">
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <use xlink:href="../../../../assets/images/sprite.svg#close"></use>
                        </svg>
                    </button>
                </div>

                <div *ngIf="isShowAccountId()" class="form-simple__item">
                    <input [mask]="'00-00-00-00-00-00'"
                           formControlName="accountId"
                           placeholder="Account Id"
                           (focus)="searchInputFocus('accountId')"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                    <button class="form-simple__reset" *ngIf="inputHasValue('accountId')" (click)="resetInput('accountId')">
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <use xlink:href="../../../../assets/images/sprite.svg#close"></use>
                        </svg>
                    </button>
                </div>

                <div class="form-simple__btns">
                    <button #searchBtn class="button snake-btn is-notouched">
                        SEARCH
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
        <div class="page__body" (scroll)="scroll($event, usersData)" *ngIf="usersData.items">
            <app-activity [activity]="usersData.items"
                          (openSettings)="openUserSettings($event)"></app-activity>
        </div>
    </div>
</div>
