import { TEMPLATE_VERSION } from "../config/template_version";
import * as i0 from "@angular/core";
var TemplateService = /** @class */ (function () {
    function TemplateService() {
        this.TEMPLATE_VERSION = TEMPLATE_VERSION;
    }
    TemplateService.prototype.isTemplate2 = function () {
        return localStorage.template === this.TEMPLATE_VERSION.version2;
    };
    TemplateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TemplateService_Factory() { return new TemplateService(); }, token: TemplateService, providedIn: "root" });
    return TemplateService;
}());
export { TemplateService };
