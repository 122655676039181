import { FILL_REQUEST_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var FillRequestService = /** @class */ (function () {
    function FillRequestService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = FILL_REQUEST_CONFIG;
    }
    FillRequestService.prototype.fillRequest = function () {
        this.loaderService.show();
        return this.http.post(this.config.request, {});
    };
    FillRequestService.prototype.sendPin = function (pin) {
        this.loaderService.show();
        return this.http.post(this.config.pin, { pin: pin });
    };
    FillRequestService.prototype.confirm = function (amount, pin) {
        this.loaderService.show();
        return this.http.post(this.config.confirm, { amount: amount, pin: pin });
    };
    FillRequestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FillRequestService_Factory() { return new FillRequestService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: FillRequestService, providedIn: "root" });
    return FillRequestService;
}());
export { FillRequestService };
