<div class="pin-popup" [class.is-active]="showPopup">
    <div class="pin-popup__inner">
        <button class="pin-popup__close" (click)="closePopup()">
            <svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                <use xlink:href="../../../../assets/images/sprite.svg#close-sign"></use>
            </svg>
        </button>

        <div class="pin-popup__title">
            <ng-template [ngIf]="popupType === PIN_POPUP_TYPE.pin">ENTER THE FOUR DIGIT PIN SENT TO YOUR PHONE ENDING IN - {{data.result}}.</ng-template>
            <ng-template [ngIf]="popupType === PIN_POPUP_TYPE.confirm">PLEASE CONFIRM</ng-template>
        </div>

        <form [formGroup]="pinForm"
              (ngSubmit)="popupType === PIN_POPUP_TYPE.pin ? onPinSubmit() : onSubmit()"
              class="pin-popup__form">
            <div [hidden]="popupType !== PIN_POPUP_TYPE.pin"
                 class="pin-popup__inputs"
                 formArrayName="digits">
                <input (keyup.enter)="onSubmit()" required *ngFor="let field of pinForm.get('digits')['controls']; let i = index" #inputs [maxLength]="1" [formControlName]="i" (keydown)="check(i, field, $event)"
                       mask="0"
                       class="login-input-small" type="number">
            </div>

            <div class="pin-popup__info" *ngIf="popupType === PIN_POPUP_TYPE.confirm">
                <div class="pin-popup__row" *ngIf="+data.result.location">
                    <div class="pin-popup__side">Money to Location</div>
                    <div class="pin-popup__side">{{data.result.location | currency}}</div>
                </div>
                <div class="pin-popup__row" *ngIf="+data.result.vendor">
                    <div class="pin-popup__side">Money to Vendor</div>
                    <div class="pin-popup__side">{{data.result.vendor | currency}}</div>
                </div>
            </div>

            <div class="text-center form-btn-submit">
                <button id="credit_card_verify_btn" class="button snake-btn is-notouched" [disabled]="!pinForm.valid">
                    <ng-template [ngIf]="popupType === PIN_POPUP_TYPE.pin">VERIFY</ng-template>
                    <ng-template [ngIf]="popupType === PIN_POPUP_TYPE.confirm">CONFIRM</ng-template>
                    <svg><rect></rect></svg>
                </button>
            </div>
        </form>


    </div>
</div>
