import { GAMES_PROVIDER_CONFIG } from "../../config/api";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../loader.service";
var GamesProviderService = /** @class */ (function () {
    function GamesProviderService(http, router, loaderService) {
        this.http = http;
        this.router = router;
        this.loaderService = loaderService;
        this.config = GAMES_PROVIDER_CONFIG;
        this.checkedGameProvider = new BehaviorSubject(null);
    }
    GamesProviderService.prototype.checkIfNewUser = function () {
        return this.router.url.includes('new-user');
    };
    GamesProviderService.prototype.getGamesProviders = function () {
        this.loaderService.show();
        var post = this.http.post(this.config.gamesProviders, {});
        return post;
    };
    GamesProviderService.prototype.setGameProvider = function (gameProvider) {
        this.checkedGameProvider.next(gameProvider);
    };
    GamesProviderService.prototype.getGameProviderName = function () {
        if (this.checkedGameProvider) {
            if (this.checkedGameProvider.value) {
                return this.checkedGameProvider.value.name;
            }
        }
        return '';
    };
    GamesProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GamesProviderService_Factory() { return new GamesProviderService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.LoaderService)); }, token: GamesProviderService, providedIn: "root" });
    return GamesProviderService;
}());
export { GamesProviderService };
