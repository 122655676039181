import {Component, EventEmitter, Input, OnInit, Output, Injectable, ViewChild, ElementRef} from '@angular/core';
import {BlurMenuService} from "../../service/blurMenu.service";
import {PAYMENTS_INFO_POPUP_TYPE} from "./payments-info-popup-config";

@Component({
    selector: 'app-payments-info-popup',
    templateUrl: './payments-info-popup.component.html',
    styleUrls: ['./payments-info-popup.component.scss']
})

@Injectable({providedIn: 'root'})
export class PaymentsInfoPopupComponent implements OnInit {
    private _showPopup;
    @Input() set showPopup(value) {
        this._showPopup = value;
        if (value) {
            this.onPopupShow();
        }

    };

    get showPopup() {
        return this._showPopup;
    }

    @Input() data;
    @Input() period;
    @Input() popupType;

    @Output() close = new EventEmitter();

    @ViewChild('snakeBtn', {static: true}) snakeBtn: ElementRef;

    selectedMonDay;
    selectedMonMonth;

    selectedSunDay;
    selectedSunMonth;

    selectedDay;
    selectedMonth;
    selectedDayName;
    selectedDate;

    PAYMENTS_INFO_POPUP_TYPE = PAYMENTS_INFO_POPUP_TYPE;

    constructor() {}

    ngOnInit() {}

    onClosePopup() {
        this.close.emit();
    }

    getTotal(array, key) {
        let total = 0;

        array.forEach(el => {
           total += +el[key];
        });

        return total;
    }

    isNegativeNumber(amount) {
        return amount < 0;
    }

    getAbs(amount) {
        return Math.abs(amount);
    }

    getTotalFromObject(obj, keyInner) {
        let total = 0;

        for (let key in obj) {
            if(key == 'fees'){
                total -= Number(obj[key][keyInner]);
            }else{
                total += Number(obj[key][keyInner]);
            }
        }

        return total;
    }

    onPopupShow() {
        this.snakeBtn.nativeElement.classList.add('is-notouched');

        this.selectedMonMonth = this.period.from.split('/')[0];
        this.selectedMonDay = this.period.from.split('/')[1];
        this.selectedSunMonth = this.period.to.split('/')[0];
        this.selectedSunDay = this.period.to.split('/')[1];

        const from = this.period.from;
        const to = this.period.to;

        if (from === to) {
            this.selectedDay = this.period.from.split('/')[1];
            this.selectedMonth = this.period.from.split('/')[0];
            this.selectedDate = new Date(this.period.from);
            this.selectedDayName = this.getDateName(this.selectedDate.getDay());
        }
    }

    showWeeklyDetails() {
        this.close.emit({
            status: true,
            type: this.PAYMENTS_INFO_POPUP_TYPE.weekly,
        });
    }

    showDailyDetails() {
        this.close.emit({
            status: true,
            type: this.PAYMENTS_INFO_POPUP_TYPE.daily,
        });
    }

    getDateName(index) {
        switch (index) {
            case 0: {
                return 'Sunday';
            }
            case 1: {
                return 'Monday';
            }
            case 2: {
                return 'Tuesday';
            }
            case 3: {
                return 'Wednesday';
            }
            case 4: {
                return 'Thursday';
            }
            case 5: {
                return 'Friday';
            }
            case 6: {
                return 'Saturday';
            }
        }
    }

    changeDay(next?) {
        let diff;
        if (next) {
            diff = +1;
        } else {
            diff = -1;
        }

        const newDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), (this.selectedDate.getDate() + diff));
        const newDayFormat = `${newDay.getMonth() + 1}/${newDay.getDate()}/${newDay.getFullYear()}`;

        this.close.emit({
            status: true,
            type: this.PAYMENTS_INFO_POPUP_TYPE.daily,
            dateFormat: newDayFormat,
            date: newDay
        });
    }

}
