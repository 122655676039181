import * as i0 from "@angular/core";
var DatesService = /** @class */ (function () {
    function DatesService() {
    }
    DatesService.prototype.getDate = function (moment) {
        // console.log(moment);
        var date = new Date(moment);
        return date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0');
        // const date = new Date(moment).toUTCString();
        //
        // console.log(date, new Date(moment), String(new Date(moment).getUTCMonth()+1), String(new Date(moment).getMonth()+1))
        //
        // return `${new Date(Date.parse(moment)).getUTCFullYear()}-${String(new Date(moment).getMonth()+1).padStart(2, '0')}-${String(new Date(Date.parse(date)).getDate()).padStart(2, '0')}`
    };
    DatesService.prototype.getMonday = function (d) {
        d = new Date(d);
        var day = d.getDay(), diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    };
    DatesService.prototype.getSunday = function (d) {
        d = new Date(d);
        var day = d.getDay(), diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff + 6));
    };
    DatesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DatesService_Factory() { return new DatesService(); }, token: DatesService, providedIn: "root" });
    return DatesService;
}());
export { DatesService };
