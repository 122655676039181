import {Component, HostListener, OnInit} from '@angular/core';
import {routerTransition, slideToRight} from "../../animations/router.animations";
import {SellService} from "../../service/sell/sell.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";

@Component({
    selector: 'app-sell',
    templateUrl: './sell.component.html',
    styleUrls: ['./sell.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': ''}
})
export class SellComponent implements OnInit {
    balance = 0;

    constructor(private sellService: SellService,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService) {}


    ngOnInit() {
        this.getBalance();
    }

    getBalance() {
        this.sellService.getLimit().subscribe(data => {
            if (data.status) {
                this.balance = data.result;
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        });
    }

    requestRefill() {
        this.sellService.requestRefill().subscribe(data => {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

}
