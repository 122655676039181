import {Component, OnInit, HostListener, ElementRef, ViewChild,} from '@angular/core';
import {routerTransition, slideToLeft} from "../../animations/router.animations";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {GamesProviderService} from "../../service/game/gamesProvider.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {RouterPaths} from "../../config/router-paths.model";
import {PROVIDERS_GAME_STATUS} from "../../config/project";

@Component({
    selector: 'app-games-provider',
    templateUrl: './games-provider.component.html',
    styleUrls: ['./games-provider.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class GamesProviderComponent implements OnInit {
    isNewUser = false;
    games = [];
    userId;

    constructor(private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private router: Router,
                private route: ActivatedRoute,
                private gamesProviderService: GamesProviderService,) {

    }


    ngOnInit() {
        this.isNewUser = this.gamesProviderService.checkIfNewUser();

        this.route.params.subscribe((params: Params) => {
            this.userId = params.id;
        });

        this.gamesProviderService.getGamesProviders().subscribe(data => {
            this.checkSubmitGame(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkSubmitGame(data) {
        try {
            if(data.status == true){
                this.games = data.result;
                if (this.games.length === 1) {
                    this.chooseGame(this.games[0], true);
                }
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text:data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    chooseGame(game, single = false) {
        const gameId = game.id;
        const userId = this.userId;

        const isGameAvailable = this.testGameIsAvailable(game);

        if (isGameAvailable) {
            this.gamesProviderService.setGameProvider(game);

            if (this.isNewUser) {
                this.router.navigate([RouterPaths.sell.newUser.create, game.id], { queryParams: { single: single }});
            } else {
                this.router.navigate([RouterPaths.sell.existingUser.info, userId], { queryParams: { gameId: gameId, single: single }});
            }
        }
    }

    testGameIsAvailable(game) {
        const status = game.status;
        const errorMessage = game.error_message;

        if (status === PROVIDERS_GAME_STATUS.notAvailable) {
            this.popupComponent.showPopupError({text: errorMessage});
            return false;
        }
        return true;
    }

    getBackLink() {
        if (this.isNewUser) {
            return RouterPaths.sell.main;
        } else {
            return RouterPaths.sell.existingUser.list;
        }
    }
}
