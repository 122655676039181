import {Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {routerTransition, slideToLeft} from "../../animations/router.animations";
import {ReportsService} from "../../service/reports.service.ts/reports.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExampleHeader} from "../../shared/datapicker-header/datapicker-header";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {PhoneMaskService} from "../../service/phoneMask.service";
import {ResetPageScrollService} from "../../service/pageScroll/resetPageScroll";
import {ReportTransactions, ReportTransactionsItem} from "../../models/reportTransactions/reportTransactions";
import {environment} from "../../../environments/environment";
import {PaginationDataModel, SetPaginationDataModel} from "../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../models/scrollPagination/paginationData";
import {VendorService} from "../../service/game/vendors/vendor.service";
import {Router} from "@angular/router";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {BlurMenuService} from "../../service/blurMenu.service";
import {DATA_TYPES} from "../../data_types";
import {PartnerService} from "../../service/partner.service";

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class ReportsComponent implements OnInit {
    reportsForm: FormGroup;
    exampleHeader = ExampleHeader;
    phoneMask = this.phoneMaskService.setPhoneMask();

    showPopupInfo = false;
    popupData;

    prevPageTopOffset = 0;
    prevChildTopOffset = 0;
    maxPageScrollPosition = 0;

    isRiverPay = this.partnerService.isRiverPay();

    transactionsResult: ReportTransactions;
    // transactionsResult = {transformItems: []}; // without model
    transactionsResultVisibility = false;
    transactionsResultUserId: number;

    activeTabId = 1;

    DATA_TYPES = DATA_TYPES;

    // usersData: PaginationDataModel = new PaginationData('users', '.js-info-row', '.js-info-body', 'users');

    usersData: PaginationDataModel = new PaginationData('users', '.js-info-row', '.js-info-body', 'users');
    cryptoData: PaginationDataModel = new PaginationData('crypto', '.js-crypto-item', '.js-crypto-parent', 'crypto');

    headerData = [];

    tabs = [
        {
            title: 'USERS',
            id: 1
        },
        {
            title: '$ IN',
            id: 2
        },
        {
            title: '$ OUT',
            id: 3
        },
    ];

    @ViewChildren('tabsEl') tabsEl: QueryList<ElementRef>;

    phoneVariants = [];

    @HostListener('click', ['$event'])
    clickOutside(e) {
        if (document.querySelector('.js-phone-input')) {
            if (!document.querySelector('.js-phone-input').contains(e.target)) {
                this.phoneVariants = [];
            }
        }
    }
    constructor(private reportsService: ReportsService,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private phoneMaskService: PhoneMaskService,
                private auth:AuthenticationService,
                private partnerService: PartnerService,
                private resetPageScrollService: ResetPageScrollService,
                private router: Router,
                private formBuilder: FormBuilder,
                private blurMenuService: BlurMenuService,
                private paginationScrollService: PaginationScrollService,
                private vendorService: VendorService,
                ) {
        this.reportsForm = this.formBuilder.group({
            phone: [null],
            from: [this.getMonday(new Date())],
            to: [this.getSunday(new Date())],
        });
    }

    data;
    page;

    ngOnInit() {
        setTimeout(() => {
            // this.getData();
            this.loadUsers(true);
        });

        document.body.addEventListener('touchstart', (e) => {
            this.resetPageScrollService.onTouch(e);
        })
    }

    onSubmit() {
        // this.getData();
        this.loadTabData(this.activeTabId);
    }

    getIssued(data){
        if(data.amount && data.reverse){
            return parseFloat(data.amount) - parseFloat(data.reverse);
        }

        if(data.amount){
            return parseFloat(data.amount);
        }

        return 0;
    }

    getWins(data){
        if(data.wins){
            return data.wins;
        }

        return 0;
    }

    isShowReportsLogs(){
        return this.vendorService.isShowReportsLogs();
    }

    isFormValid() {
        const phone = this.reportsForm.controls.phone.value;
        const from = this.reportsForm.controls.from.value;
        const to = this.reportsForm.controls.to.value;
        if (phone || from || to) {
            return true;
        }
        return false;
    }

    initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
        paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
    }



    ///////---SCROLLING PAGINATION FUNCTIONAL---///////

    // DETECTING SCROLL
    scroll(e, settings) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        const direction = this.prevChildTopOffset > e.target.scrollTop ? 'up' : 'down';

        // console.log(direction === 'up', e.target.scrollTop === 0, document.querySelectorAll('.page')[0].style.overflowY === 'hidden')

        if (direction === 'up' && e.target.scrollTop === 0 && (<HTMLElement>document.querySelectorAll('.page')[0]).style.overflowY === 'hidden') {
            (<HTMLElement>document.querySelectorAll('.page')[0]).style.overflowY = 'auto'
        }

        this.prevChildTopOffset = e.target.scrollTop;
        this.paginationScrollService.onScroll(e, settings, this.loadNewData.bind(this));
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page, step = 'end', settings):any {
        const phone = this.reportsForm.controls.phone.value ? this.reportsForm.controls.phone.value : '';
        const from = this.getFilterFrom();
        const to = this.getFilterTo();

        if (settings.name ===  'users') {
          this.reportsService.getData('membership', page, '', phone, from, to).subscribe(data => {
            this.paginationScrollService.dataAddingLogic(page, step, data, settings)
          });
        } else if (settings.name ===  'crypto') {
          this.reportsService.getData('crypto', '', page, phone, from, to).subscribe(data => {
            this.paginationScrollService.dataAddingLogic(page, step, data, settings)
          });
        }

    }

    getPhoneMask(item){
        if(item.country_id){
            return this.phoneMaskService.getPhoneMaskByCountryId(item.country_id);
        }else{
            return this.phoneMaskService.getDefaultPhoneMask();
        }

    }

    getDate(moment) {
        // console.log(moment);
        const date = new Date(moment);
        return `${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`

        // const date = new Date(moment).toUTCString();
        //
        // console.log(date, new Date(moment), String(new Date(moment).getUTCMonth()+1), String(new Date(moment).getMonth()+1))
        //
        // return `${new Date(Date.parse(moment)).getUTCFullYear()}-${String(new Date(moment).getMonth()+1).padStart(2, '0')}-${String(new Date(Date.parse(date)).getDate()).padStart(2, '0')}`
    }

    getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    getSunday(d) {
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff+6));
    }

    isIos(){
        let userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone/.test( userAgent );
        // return /iphone|ipad|ipod/.test( userAgent );
    }

    pageScroll($event, to) {
        console.log($event, to);

        const direction = this.prevPageTopOffset > $event.target.scrollTop ? 'up' : 'down';
        const waitingTime = direction === 'up' ? 500 : 0;
        const pageHasScroll = $event.target.scrollHeight;

        const toOffset = 20;
        const toMaxPosition = to.offsetTop - toOffset;

        setTimeout(() => {
            if (($event.target.scrollTop > toMaxPosition) && pageHasScroll) {

                $event.target.style.overflowY = 'hidden';
                $event.target.scrollTop = toMaxPosition;
            }
        }, waitingTime);

        this.prevPageTopOffset = $event.target.scrollTop;
    }

    getTransactions(id) {
        this.transactionsResultUserId = id;
        this.loaderService.show();
        this.reportsService.getTransactions(id).subscribe(data => {
            this.getTransactionsResult(data);
        }, () => {
            this.popupComponent.showPopupTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    getTransactionsResult(data) {
        try {
            if (data.status) {
                this.showTransactionsResult(data.result);
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.popupComponent.showPopupTryAgain();
            }
        } catch (e) {
            this.popupComponent.showPopupTryAgain();
        }
    }

    showTransactionsResult(result) {
        this.transactionsResult = new ReportTransactions(result);
        // this.transactionsResult.transformItems = result; // without model
        this.transactionsResultVisibility = true;
    }

    hideTransactionsResult() {
        this.transactionsResultVisibility = false;
        this.transactionsResultUserId = null;
    }

    reverseHandler() {
        this.loaderService.show();
        this.reportsService.reverseTransaction(this.transactionsResultUserId).subscribe(data => {
            this.getReverseResult(data, this.transactionsResultUserId);
        }, () => {
            this.popupComponent.showPopupTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    getReverseResult(data, id) {
        try {
            if (data.status) {
                this.showReverseSuccess(data, id);
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.popupComponent.showPopupTryAgain();
            }
        } catch (e) {
            this.popupComponent.showPopupTryAgain();
        }
    }

    showReverseSuccess(data, id) {
        this.popupComponent.showPopupSuccess({text: data.message});
        this.getTransactions(this.transactionsResultUserId);

        // this.transactionsResult.transformItems.forEach((el, index) => {
        //   this.transactionsResult.transformItems[index].updateReverse(false);
        // });
    }

    openUserInfo(id) {
        this.router.navigate(['/home/sell/existing-user/existing-user-games', id]);
    }

    onShowPopupInfo() {
        switch (this.activeTabId) {
            case this.tabs[0].id: {
                this.loadUsersPopup();
                break;
            }
            case this.tabs[1].id: {
                this.loadInPopup();
                break;
            }
            case this.tabs[2].id: {
                this.loadOutPopup();
                break;
            }
        }

        this.showPopupInfo = true;
        this.blurMenuService.blur();
    }

    onHidePopupInfo() {
        this.showPopupInfo = false;
        this.blurMenuService.focus();
    }

    loadUsersPopup() {
        const page = this.page ? this.page : 1;
        const phone = this.reportsForm.controls.phone.value ? this.reportsForm.controls.phone.value : '';
        // const from = this.reportsForm.controls.from.value ? this.getDate(this.reportsForm.controls.from.value) : '';
        // const to = this.reportsForm.controls.to.value ? this.getDate(this.reportsForm.controls.to.value) : '';

        const from = this.getFilterFrom();
        const to = this.getFilterTo();

        this.reportsService.getDataPopup('', page, phone, from, to).subscribe(data => {
            this.getPopupResult(data, this.tabs[0].id);
        }, () => {
            this.popupComponent.showPopupTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    loadInPopup() {
        const phone = this.reportsForm.controls.phone.value;
        const from = this.getFilterFrom();
        const to = this.getFilterTo();

        this.reportsService.getInInfoPopup(phone, from, to).subscribe(data => {
            this.getPopupResult(data, this.tabs[1].id);
        }, () => {
            this.popupComponent.showPopupTryAgain();
        }, () => {
            this.loaderService.hide();

        });
    }

    loadOutPopup() {
        const phone = this.reportsForm.controls.phone.value;
        const from = this.getFilterFrom();
        const to = this.getFilterTo();

        this.reportsService.getOutInfoPopup(phone, from, to).subscribe(data => {
            this.getPopupResult(data, this.tabs[2].id);
        }, () => {
            this.popupComponent.showPopupTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    getPopupResult(data, tabId) {
        try {
            if (data.status) {
                switch (tabId) {
                    case this.tabs[0].id: {
                        let totalGifted = 0;
                        data.giftedList.forEach(el => {
                            totalGifted += +el.amount;
                        });

                        const giftedList = data.giftedList.map(el => {
                            return {
                                title: el.game_provider_name,
                                amount: el.amount
                            }
                        });

                        this.popupData = [
                            {
                                title: 'TOTAL USERS',
                                type: this.DATA_TYPES.number,
                                list: [
                                    {
                                        title: 'New Users',
                                        amount: data.totalNewUsers,
                                    },
                                    {
                                        title: 'Existing Users',
                                        amount: data.totalExistingUsers,
                                    }
                                  ]
                            },
                            {
                                title: 'TOTAL GIFTED',
                                type: this.DATA_TYPES.currency,
                                list: giftedList
                            },
                          ];
                        break;
                    }
                    case this.tabs[1].id: {
                        this.popupData = [
                            {
                                title: 'MEMBERSHIP',
                                type: this.DATA_TYPES.currency,
                                list: [
                                    {
                                        title: 'Cash',
                                        amount: data.membershipCash
                                    },
                                    {
                                        title: 'Card',
                                        amount: data.membershipCard
                                    }
                                ]
                            },
                            // {
                            //     title: 'USDC',
                            //     type: this.DATA_TYPES.currency,
                            //     list: [
                            //         {
                            //             title: 'Cash',
                            //             amount: data.usdcCash
                            //         },
                            //         {
                            //             title: 'Card',
                            //             amount: data.usdcCard
                            //         }
                            //     ]
                            // },
                            {
                                title: 'CRYPTO',
                                type: this.DATA_TYPES.currency,
                                list: [
                                    {
                                        title: 'Cash',
                                        amount: data.cryptoCash
                                    },
                                    {
                                        title: 'Card',
                                        amount: data.cryptoCard
                                    }
                                ]
                            }
                        ];
                        break;
                    }
                    case this.tabs[2].id: {
                        this.popupData = [];

                        this.popupData = data.result.map(el => {
                            return {
                                title: el.gameVendorName,
                                type: this.DATA_TYPES.currency,
                                list: [
                                    {
                                        title: 'Cash',
                                        amount: el.cash
                                    },
                                    {
                                        title: 'Check',
                                        amount: el.check
                                    },
                                    {
                                        title: 'CashApp',
                                        amount: el.cashapp
                                    },
                                    // {
                                    //     title: 'USDC',
                                    //     amount: el.usdc
                                    // },
                                    {
                                        title: 'Reversed',
                                        amount: el.reversed
                                    }
                                  ]
                            }
                        });
                        break;
                    }
                }
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.popupComponent.showPopupTryAgain();
            }
        } catch (e) {
            this.popupComponent.showPopupTryAgain();
        }
    }

    onTabChange(el, tab) {
        const currentTab = el.currentTarget;
        this.activeTabId = tab;

        this.tabsEl.forEach(el => el.nativeElement.classList.remove('is-active'));
        currentTab.classList.add('is-active');
        this.loadTabData(tab);
    }

    loadTabData(tab) {
        switch (tab) {
            case this.tabs[0].id: {
                this.loadUsers(true);
                break;
            }
            case this.tabs[1].id: {
                this.loadInInfo();
                this.loadUsers();
                break;
            }
            case this.tabs[2].id: {
                this.loadOutInfo();
                this.loadUsers();
                break;
            }
        }
    }

    loadUsers(main?) {
        const currentPage = !!main;
        const page = this.page ? this.page : 1;
        const phone = this.reportsForm.controls.phone.value ? this.reportsForm.controls.phone.value : '';
        // const from = this.reportsForm.controls.from.value ? this.getDate(this.reportsForm.controls.from.value) : '';
        // const to = this.reportsForm.controls.to.value ? this.getDate(this.reportsForm.controls.to.value) : '';

        const from = this.getFilterFrom();
        const to = this.getFilterTo();

        this.loaderService.show();
        this.reportsService.getData('', page, page, phone, from, to).subscribe(data => {
            this.getTabResult(data, this.tabs[0].id, currentPage);
        }, () => {
            this.popupComponent.showPopupTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    loadInInfo() {
        const phone = this.reportsForm.controls.phone.value;
        const from = this.getFilterFrom();
        const to = this.getFilterTo();

        this.reportsService.getInInfo(phone, from, to).subscribe(data => {
            this.getTabResult(data, this.tabs[1].id, true);
        }, () => {
            this.popupComponent.showPopupTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    loadOutInfo() {
        const phone = this.reportsForm.controls.phone.value;
        const from = this.getFilterFrom();
        const to = this.getFilterTo();

        this.reportsService.getOutInfo(phone, from, to).subscribe(data => {
            this.getTabResult(data, this.tabs[2].id, true);
        }, () => {
            this.popupComponent.showPopupTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    getTabResult(data, tabId, main) {
        try {
            if (data.status) {
                switch (tabId) {
                    case this.tabs[0].id: {
                        this.paginationScrollService.resetPagination(this.usersData);
                        this.data = data;

                        const membershipDuesDataSettings: SetPaginationDataModel = {
                          itemsList: data.users,
                          scrollLimit: data.limit,
                          total: data.totalUsers
                        };
                        this.initScrolledData(this.usersData, membershipDuesDataSettings);

                        const soldCryptoDataSettings: SetPaginationDataModel = {
                          itemsList: data.crypto,
                          scrollLimit: data.limit,
                          total: data.totalCrypto
                        };
                        this.initScrolledData(this.cryptoData, soldCryptoDataSettings);

                        if (main) {
                          this.headerData = [
                            {
                              title: 'EXISTING USERS',
                              amount: data.totalUsers,
                              type: this.DATA_TYPES.number,
                            },
                            {
                              title: 'NEW USERS',
                              amount: data.totalNewUsers,
                              type: this.DATA_TYPES.number,
                            },
                            {
                              title: 'GIFTED',
                              amount: data.totalGifted,
                              type: this.DATA_TYPES.currency,
                            },
                          ];
                        }

                        break;
                    }
                    case this.tabs[1].id: {
                        const total = +data.totalCash + +data.totalCard;

                        this.headerData = [
                            {
                                title: 'TOTAL ISSUED',
                                amount: total,
                                type: this.DATA_TYPES.currency
                            },
                            {
                                title: 'CASH',
                                amount: data.totalCash,
                                type: this.DATA_TYPES.currency,
                            },
                            {
                                title: 'CARD',
                                amount: data.totalCard,
                                type: this.DATA_TYPES.currency,
                            },
                        ];
                        break;
                    }
                    case this.tabs[2].id: {
                        const total = +data.totalCash + +data.totalCheck + +data.totalCrypto + +data.totalReversed + +data.totalCashapp;

                        this.headerData = [
                            {
                                title: 'TOTAL',
                                amount: total,
                                type: this.DATA_TYPES.currency,
                            },
                            {
                                title: 'CASH',
                                amount: data.totalCash,
                                type: this.DATA_TYPES.currency,
                            },
                            {
                                title: 'CHECK',
                                amount: data.totalCheck,
                                type: this.DATA_TYPES.currency,
                            }
                        ];

                        this.headerData.push(
                          {
                              title: 'CASHAPP',
                              amount: data.totalCashapp,
                              type: this.DATA_TYPES.currency,
                          }
                        );

                        if (!this.isRiverPay) {
                            this.headerData.push(
                              {
                                  title: 'CRYPTO',
                                  amount: data.totalCrypto,
                                  type: this.DATA_TYPES.currency,
                              }
                            );
                        }
                        this.headerData.push(
                          {
                              title: 'REVERSED',
                              amount: data.totalReversed,
                              type: this.DATA_TYPES.currency,
                          }
                        );

                        break;
                    }
                }
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.popupComponent.showPopupTryAgain();
            }
        } catch (e) {
            this.popupComponent.showPopupTryAgain();
        }
    }

    toggleDropdown(e) {
      const hidden = e.target.offsetParent.classList.contains('is-hidden') ? true : false;
      document.body.querySelectorAll('.js-dropdown').forEach(el => {
        el.classList.add('is-hidden');
      });

      if (hidden) {
        e.target.offsetParent.classList.remove('is-hidden');

        setTimeout(() => {
          const pageScroll = (<HTMLElement>document.querySelectorAll('.page')[0]).scrollHeight - (<HTMLElement>document.querySelectorAll('.page')[0]).offsetHeight;
          const scrollTo = pageScroll <= this.maxPageScrollPosition || this.maxPageScrollPosition === 0 ? pageScroll : this.maxPageScrollPosition;

          (<HTMLElement>document.querySelectorAll('.page')[0]).scrollIntoView({behavior: 'smooth'});
          (<HTMLElement>document.querySelectorAll('.page')[0]).scroll({
            top: scrollTo,
            left: 0,
            behavior: 'smooth'
          });
        }, 350)

      }
    }

    getFilterFrom(){
        return (<HTMLInputElement>document.querySelector('[formcontrolname=from]')).value;
    }

    getFilterTo(){
        return (<HTMLInputElement>document.querySelector('[formcontrolname=to]')).value;
    }

    choosePhone(phone) {
        this.reportsForm.controls.phone.setValue(phone);
        this.onSubmit();
        this.phoneVariants = [];
    }

    onPhoneInput() {
        const value = this.reportsForm.controls.phone.value;
        if (value.length >= 3) {
            this.reportsService.getPhonesList(value).subscribe(data => {
               if (data && data.length) {
                   this.phoneVariants = data;
               } else {
                   this.phoneVariants = [];
               }
            });
        } else {
            this.phoneVariants = [];
        }
    }
}
