import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuService} from "../../service/menu.service";
import {ReportTransactionsItem} from "../../models/reportTransactions/reportTransactions";
import {opacity, slideToLeft} from "../../animations/router.animations";

@Component({
    selector: 'app-transactions-popup',
    templateUrl: './transactions-popup.component.html',
    styleUrls: ['./transactions-popup.component.scss'],
    animations: [opacity],
    host: {'[@enterAnimation]': ''}
})
export class TransactionsPopupComponent implements OnInit {
    @Input() transactionsResult: [ReportTransactionsItem?];
    @Output() close = new EventEmitter();
    @Output() reverseAction = new EventEmitter();

    constructor(private menuService:MenuService) {
    }

    ngOnInit() {

    }

    reverseHandler(id) {
        this.reverseAction.emit(id);
    }
    closePopup() {
        this.close.emit();
    }
}
