import {Component, HostListener, OnInit} from '@angular/core';
import {routerTransition, slideToRight} from "../../animations/router.animations";
import {TERMS_AND_CONDITIONS_TEXT} from "./terms-and-conditions.text";
import {TERMS_AND_CONDITIONS_CONTENT_TYPES} from "./terms-and-conditions.config";
import {PartnerService} from "../../service/partner.service";
import {PARTNERS_INFO} from "../../config/project";

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': ''}
})
export class TermsAndConditionsComponent implements OnInit {
    content = TERMS_AND_CONDITIONS_TEXT;
    TERMS_AND_CONDITIONS_CONTENT_TYPES = TERMS_AND_CONDITIONS_CONTENT_TYPES;
    isRiverPay = this.partnerService.isRiverPay();

    constructor(private partnerService: PartnerService,) {}


    ngOnInit() {}

    correctPartner(text) {
        const newText = text;
        if (this.isRiverPay) {
            if (newText === "<a href=\"mailto:info@eboxenterprises.com\">info@eboxenterprises.com</a>") {
                return '';
            }
           return newText.replace(new RegExp("eBox Enterprises", "g"), PARTNERS_INFO.riverPay.name).replace(new RegExp("eboxenterprises.com", "g"), '');
        }
        return newText;
    }

}
