export const RouterPaths = {
  sell: {
    main: '/home/sell',
    newUser: {
      create: '/home/sell/new-user',
      selectGame: '/home/sell/games-providers/new-user'
    },
    existingUser: {
      list: '/home/sell/existing-user',
      info: '/home/sell/existing-user/existing-user-info',
      selectGame: '/home/sell/existing-user/existing-user-games',
  },
  },
  reports: {
    main: '/home/reports',
  },
  directDeposit: {
    main: '/home/direct-deposit',
  },
  collections: {
    main: '/home/collections',
    summary: '/home/collections/summary',
  },
  termsAndConditions: {
    main: '/home/terms-and-conditions'
  },
  fillRequestForm: {
    main: '/home/fill-request-form'
  }
};
