var ActivityItem = /** @class */ (function () {
    function ActivityItem(data) {
        if (data) {
            this._phone = data.phone;
            this._pin = data.pin;
            this._id = data.id;
        }
    }
    Object.defineProperty(ActivityItem.prototype, "phone", {
        get: function () {
            return this._phone;
        },
        set: function (value) {
            this._phone = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActivityItem.prototype, "pin", {
        get: function () {
            return this._pin;
        },
        set: function (value) {
            this._pin = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActivityItem.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (value) {
            this._id = value;
        },
        enumerable: true,
        configurable: true
    });
    return ActivityItem;
}());
export { ActivityItem };
