<div class="page page--primary">
    <div class="page__inner">
        <a [routerLink]="getBackLink()" class="page__back">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">Direct Deposit</h1>
        <div class="page__form">
            <form [formGroup]="depositForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <input formControlName="firstName"
                           placeholder="First Name*"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__item">
                    <input formControlName="lastName"
                           placeholder="Last Name*"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__item">
                    <input formControlName="bankName"
                           placeholder="Bank Name*"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__item">
                    <input formControlName="routingNumber"
                           mask="0*"
                           placeholder="Routing Number*"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__item">
                    <input formControlName="accountNumber"
                           placeholder="Account Number*"
                           mask="0*"
                           [type]="'tel'"
                           (input)="onReEnterAccountNumber($event)"
                           [class.is-error]="isAccountNumberError"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__item">
                    <input formControlName="reEnterAccountNumber"
                           mask="0*"
                           placeholder="Re-Enter Account Number*"
                           [type]="'tel'"
                           (input)="onReEnterAccountNumber($event)"
                           [class.is-error]="isAccountNumberError"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__error form-simple__note" [class.is-active]="isAccountNumberError">
                    Your Bank Accounts do not match. Please verify that you entered them correctly.
                </div>
                <div class="form-simple__item">
                    <input formControlName="address"
                           placeholder="Address*"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__item">
                    <input formControlName="city"
                           placeholder="City*"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__item">
                    <input formControlName="state"
                           placeholder="State*"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__item">
                    <input formControlName="zip"
                           mask="00000"
                           placeholder="Zip Code*"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__item">
                    <mat-form-field appearance="outline">
                        <input formControlName="birth" readonly placeholder="Date of Birth*" matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                            <svg matDatepickerToggleIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5338 2.43848H7.46387V3.84468H16.5338V2.43848Z" fill="white"/>
                                <path d="M6.05727 3.14148H4.66699V3.68846H6.05727V3.14148Z" fill="white"/>
                                <path d="M19.3307 3.14148H17.9404V3.68846H19.3307V3.14148Z" fill="white"/>
                                <path d="M20.7372 2.43848V5.09479H16.5345V3.14155H7.46456V5.09479H3.26189V2.43848H0V6.79766H23.9991V2.43848H20.7372Z" fill="white"/>
                                <path d="M0 8.20386V24.0001H23.9991V8.20386H0ZM5.24512 21.3752H2.92163V19.969H5.24512V21.3752ZM5.24512 18.3285H2.92163V16.9223H5.24512V18.3285ZM5.24512 15.2817H2.92163V13.8755H5.24512V15.2817ZM9.20306 21.3752H6.88003V19.969H9.20306V21.3752ZM9.20306 18.3285H6.88003V16.9223H9.20306V18.3285ZM9.20306 15.2817H6.88003V13.8755H9.20306V15.2817ZM13.161 21.3752H10.838V19.969H13.161V21.3752ZM13.161 18.3285H10.838V16.9223H13.161V18.3285ZM13.161 15.2817H10.838V13.8755H13.161V15.2817ZM13.161 12.235H10.838V10.8288H13.161V12.235ZM17.119 21.3752H14.796V19.969H17.119V21.3752ZM17.119 18.3285H14.796V16.9223H17.119V18.3285ZM17.119 15.2817H14.796V13.8755H17.119V15.2817ZM17.119 12.235H14.796V10.8288H17.119V12.235ZM21.0774 18.3285H18.7539V16.9223H21.0774V18.3285ZM21.0774 15.2817H18.7539V13.8755H21.0774V15.2817ZM21.0774 12.235H18.7539V10.8288H21.0774V12.235Z" fill="white"/>
                                <path d="M6.05727 0H4.66699V3.68845H6.05727V0Z" fill="white"/>
                                <path d="M19.3307 0H17.9404V3.68845H19.3307V0Z" fill="white"/>
                            </svg>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="form-simple__item">
                    <input mask="000-00-0000"
                           formControlName="ssn"
                           placeholder="SSN"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__note">
                    <b>Note:</b> Credit Card Processor may ask for your entire SSN to verify your bank account. It’s entirely up to its discretion.
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched" [disabled]="depositForm.invalid || isAccountNumberError">
                        Submit
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

