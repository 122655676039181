import { SELL_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var SellService = /** @class */ (function () {
    function SellService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = SELL_CONFIG;
    }
    SellService.prototype.getLimit = function () {
        return this.http.post(this.config.getLimit, {});
    };
    SellService.prototype.requestRefill = function () {
        this.loaderService.show();
        return this.http.post(this.config.requestRefill, {});
    };
    SellService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SellService_Factory() { return new SellService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: SellService, providedIn: "root" });
    return SellService;
}());
export { SellService };
