import {Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {routerTransition, slideToLeft} from '../../animations/router.animations';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from '../../shared/popup/popup.component';
import {formatNumber,formatCurrency} from '@angular/common';
import {LoaderService} from '../../service/loader.service';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FillRequestService} from "../../service/fillRequest/fillRequest.service";
import {RouterPaths} from "../../config/router-paths.model";

@Component({
    selector: 'app-fill-request-form',
    templateUrl: './fill-request-form.component.html',
    styleUrls: ['./fill-request-form.component.scss'],
    // animations: [routerTransition('left')],
    // host: {'[@routerTransition]': ''}
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class FillRequestFormComponent implements OnInit {
    fillRequestForm: FormGroup;

    pinForm: FormGroup;
    numOfDigits = 4;

    showPinForm = false;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    constructor(private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private fillRequestService: FillRequestService,
                private route: ActivatedRoute,
                private router: Router,
                private popupComponent: PopupComponent,) {}

    ngOnInit() {
        this.fillRequestForm = this.formBuilder.group({
            amount: [null]
        });

        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([])
        });
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required));
        }
    }

    onSubmit() {
        this.fillRequestService.fillRequest().subscribe(response => {
            if (response.status) {
                this.showPinForm = true;
            } else {
                this.popupComponent.showPopupError({text: response.message});
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    resendSms() {
        this.pinForm.reset();
        this.onSubmit();
    }

    onSubmitPin() {
        let pin = this.pinForm.controls.digits.value;
        pin = pin.join('');

        if (pin.length !== 4) {
            this.popupComponent.showPopupError({text: `The Code should consist of ${this.numOfDigits} numerical characters`});
            return
        }

        this.fillRequestService.sendPin(pin).subscribe(response => {
            if (response.status) {
                this.onSubmitPinSuccess();
            } else {
                this.popupComponent.showPopupError({text: response.message});
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    onSubmitPinSuccess() {
        const amount = this.fillRequestForm.controls.amount.value;
        const text = `Please confirm refill to ${formatCurrency(amount, 'en','$')}`

        this.popupComponent.showPopupAgreement({text: text,
            confirmButtonText: 'CONFIRM<svg><rect></rect></svg>',
            cancelButtonText: '<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z" fill="#FDFD88"/>\n' +
              '</svg>'}, (status) => {
            if (status) {
                this.submit();
            }
        });

        this.showPinForm = true;
    }

    submit() {
        const amount = this.fillRequestForm.controls.amount.value;
        let pin = this.pinForm.controls.digits.value;
        pin = pin.join('');

        this.fillRequestService.confirm(amount, pin).subscribe(response => {
            if (response.status) {
                this.onRefillSuccess(response.message);
            } else {
                this.popupComponent.showPopupError({text: response.message});
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onRefillSuccess(message) {
        this.popupComponent.showPopupSuccess({text: message});
        this.showPinForm = false;
        this.pinForm.reset();
        this.fillRequestForm.reset();
    }

    goBack() {
        if (this.showPinForm) {
            this.showPinForm = false;
            this.pinForm.reset();
        } else {
            this.router.navigate([RouterPaths.sell.main]);
        }
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }

        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        } else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }

}
