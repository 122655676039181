import {Component, EventEmitter, Input, OnInit, Output,Injectable} from '@angular/core';

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss']
})

@Injectable({providedIn: 'root'})
export class SummaryComponent implements OnInit {

    @Input() items;

    constructor() {
    }

    ngOnInit() {

    }

}
