import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";
import {
    PaginationDataModel,
    PaginationResponseModel,
    SetPaginationDataModel
} from "../../models/scrollPagination/paginationData.model";

@Injectable({
    providedIn: 'root'
})
export class PaginationScrollService {
    // public visible = new BehaviorSubject(false);

    constructor() {

    }

    resetPagination(data: PaginationDataModel) {
        data.prevPagination = 0;
        data.previewPagination = 1;
        data.lastScrollTop = 0;
        data.items = [];
        data.loading = false;
        data.countLastAddedItems = 0;
        data.lastAddedItem = 0;
    }

    initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
        paginationDataName.items = settings.itemsList;
        paginationDataName.limit = settings.scrollLimit ? settings.scrollLimit : 40;
        paginationDataName.countTotalItems = settings.total;
        paginationDataName.lastAddedItem = paginationDataName.items.length;
        paginationDataName.dataRichEnd = +paginationDataName.countTotalItems === +paginationDataName.lastAddedItem;
        paginationDataName.canScroll = paginationDataName.items.length >= paginationDataName.limit && !paginationDataName.dataRichEnd;

        return paginationDataName;
    }

    onScroll(e, settings: PaginationDataModel, loaderFunction) {
        // console.log(!settings.loading, settings.canScroll);

        // console.log('services', settings);

        if (!settings.loading && settings.canScroll) {
            const elems = document.querySelectorAll(settings.selector);

            const rect = elems[elems.length - 1] !== undefined ? elems[elems.length - 1].getBoundingClientRect() : undefined;
            let rectStart;
            let documentRect = document.querySelector(settings.selectorParent).getBoundingClientRect();

            if (settings.prevPagination > 0) {
                rectStart = elems[0] !== undefined ? elems[Math.round(settings.limit*.8)].getBoundingClientRect() : undefined;
            } else {
                rectStart = undefined;
            }

            var st = e.target.pageYOffset || e.target.scrollTop;
            if (st > settings.lastScrollTop){
                if (rect !== undefined && rect.bottom < documentRect.bottom + rect.height * Math.round(settings.limit*.2) && !settings.dataRichEnd) {
                    settings.loading = true;
                    const loadingPage = settings.previewPagination + 1;
                    loaderFunction(loadingPage, 'end', settings);
                }

            } else {
                if (e.target.scrollTop <= rect.height * 15  && settings.prevPagination > 0) {
                    settings.loading = true;
                    const loadingPage = (settings.countLastAddedItems < settings.limit &&  settings.countLastAddedItems !== 0) ? settings.prevPagination - 1 : settings.prevPagination;
                    loaderFunction(loadingPage, 'start', settings);
                }
            }
            settings.lastScrollTop = st <= 0 ? 0 : st;

        }
    }


    // ADDING NEW DATA
    dataAddingLogic(page: number, step: string, data: PaginationResponseModel, settings: PaginationDataModel) {
        // PAGINATION FIXES
        // settings.countTotalItems = data.total;

        if (data.status) {

            // ADD PAGE TO THE END
            if (step === 'end') {

                // UPDATE SETTINGS
                settings.lastAddedItem += data[settings.itemsListIfNoResult].length;
                settings.previewPagination += 1;
                if (+settings.lastAddedItem === +settings.countTotalItems) {
                    settings.dataRichEnd = true;
                }

                // DATA RICH END LOGIC
                if (settings.dataRichEnd) {
                    settings.countLastAddedItems = data[settings.itemsListIfNoResult].length;
                    if (settings.items.length > (settings.limit * 2)) {
                        settings.prevPagination += 1;
                    }

                } else {
                    // REMOVE PAGE FROM BEGINING
                    if (settings.items.length > (settings.limit * 2)) {
                        settings.items.splice(0, data[settings.itemsListIfNoResult].length);
                        settings.prevPagination += 1;
                    }
                }

                // ADD PAGE TO THE END
                for (let i = 0; i < data[settings.itemsListIfNoResult].length; i++) {
                    settings.items.push(data[settings.itemsListIfNoResult][i]);
                };
            }

            // ADD PAGE TO BEGIN
            if ((step === 'start') && settings.prevPagination) {

                let additionalRemovedItems = 0;

                if (settings.countLastAddedItems < settings.limit && settings.countLastAddedItems !== 0) {
                    additionalRemovedItems = settings.countLastAddedItems;
                    settings.prevPagination -= 2;
                    settings.previewPagination -= 2;
                }
                else {
                    settings.prevPagination -= 1;
                    settings.previewPagination -= 1;
                }

                // REMOVE PAGE FROM END
                settings.items.splice(settings.items.length - data[settings.itemsListIfNoResult].length - additionalRemovedItems, data[settings.itemsListIfNoResult].length + additionalRemovedItems);
                settings.lastAddedItem -= data[settings.itemsListIfNoResult].length + additionalRemovedItems;

                // ADD PAGE TO BEGINING
                for (let i = data[settings.itemsListIfNoResult].length-1; i >= 0; i--) {
                    settings.items.unshift(data[settings.itemsListIfNoResult][i]);
                }
                settings.dataRichEnd = false;

            }

            settings.loading = false;
        }
    }
}
