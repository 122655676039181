import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {STRIPE_CONFIG} from "../../config/api";

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    public visible = new BehaviorSubject(null);
    private config = STRIPE_CONFIG;

    constructor(private ngZone: NgZone,
                private http: HttpClient) {

    }

    getPublishableKey() {
        return this.http.post<any>(this.config.getPublishableKey, {});
    }

    setPublishableKey(key){
        //Stripe.setPublishableKey(key);
    }

    createCardToken(number,cvc,expMonth,expYear,handler){
        // Stripe.createToken({
        //     number: number,
        //     cvc: cvc,
        //     exp_month: expMonth,
        //     exp_year: expYear,
        // }, function(status, response) {
        //     handler(response);
        //
        //     //console.log("gggggg");
        //
        //     //console.log(response);
        //     if (response.error) {
        //         // var settingsError = {
        //         //     title:'Error',
        //         //     text:response.error.message,
        //         //     type:'error'
        //         // }
        //         // settings.errorFunction(settingsError);
        //     }
        //     else {
        //         // var request = $.ajax ({
        //         //     type: "POST",
        //         //     url: settings.cahgeCardController,
        //         //     dataType: "json",
        //         //     data: {
        //         //         "stripeToken" : response.id
        //         //     }
        //         // });
        //         //
        //         // request.done(function(objStripe){
        //         //     if(objStripe.status == true){
        //         //         var settingsFunction = {
        //         //             stripeData:objStripe.data
        //         //         };
        //         //         settings.requestDoneFunction(settingsFunction);
        //         //     }else{
        //         //         var settingsError = {
        //         //             title:'Error',
        //         //             text:objStripe.message,
        //         //             type:'error'
        //         //         }
        //         //         settings.errorFunction(settingsError);
        //         //     }
        //         // });
        //         //
        //         // request.fail(function(jqXHR, textStatus) {
        //         //     var settingsError = {
        //         //         title:'Error',
        //         //         text:"Error: failed to call Controller to process the transaction.",
        //         //         type:'error'
        //         //     }
        //         //     settings.errorFunction(settingsError);
        //         // });
        //     }
        // });
    }
}
