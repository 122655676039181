/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./activity.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-mask";
import * as i4 from "./activity.component";
import * as i5 from "../../service/phoneMask.service";
var styles_ActivityComponent = [i0.styles];
var RenderType_ActivityComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivityComponent, data: {} });
export { RenderType_ActivityComponent as RenderType_ActivityComponent };
function View_ActivityComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "li", [["class", "activity__item js-activity-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "button", [["class", "activity__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openUserSettings(_co.getId(_v.context.$implicit)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "activity__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "activity__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "activity__info"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "activity__right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, ":svg:svg", [["class", "activity__direction"], ["height", "36"], ["width", "29"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#settings"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _co.getPhone(_v.context.$implicit), _co.getPhoneMask(_v.context.$implicit))); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.getPin(_v.context.$implicit); _ck(_v, 7, 0, currVal_1); }); }
function View_ActivityComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "activity"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "activity__list"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.listScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activity; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ActivityComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.MaskPipe, [i3.MaskApplierService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activity; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ActivityComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activity", [], null, null, null, View_ActivityComponent_0, RenderType_ActivityComponent)), i1.ɵdid(1, 114688, null, 0, i4.ActivityComponent, [i5.PhoneMaskService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActivityComponentNgFactory = i1.ɵccf("app-activity", i4.ActivityComponent, View_ActivityComponent_Host_0, { activity: "activity" }, { openSettings: "openSettings", listScrolled: "listScrolled" }, []);
export { ActivityComponentNgFactory as ActivityComponentNgFactory };
