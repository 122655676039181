<div class="page page--primary">
    <div class="page__inner">
        <button (click)="goBack()" class="page__back">
            <span class="visually-hidden">Back</span>
        </button>
        <h1 class="page__title">Fill Request Form</h1>
        <div class="page__form">
            <form *ngIf="!showPinForm" [formGroup]="fillRequestForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item form-simple__item--amount">
                    <input mask="separator.0"
                           [prefix]="fillRequestForm.controls.amount.value ? '$' : ''"
                           thousandSeparator=","
                           formControlName="amount"
                           placeholder="Amount"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                    <span>.00</span>
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched" [disabled]="fillRequestForm.invalid">
                        Submit
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>

            <form *ngIf="showPinForm" [formGroup]="pinForm" (ngSubmit)="onSubmitPin()" class="login__form">
                <div class="login__items" formArrayName="digits">
                    <input (keydown.enter)="onSubmitPin()" *ngFor="let field of pinForm.get('digits')['controls']; let i = index" #inputs [maxLength]="1" [formControlName]="i"  (keydown)="check(i, field, $event)"
                           mask="0"
                           class="login-input-small" type="tel">
                </div>

                <button (click)="resendSms()" type="button" class="text t-light login-text login__resend">Resend</button>

                <div class="form-simple__btns">
                    <button type="submit" id="verify" type="submit" class="button snake-btn is-notouched" [disabled]="!pinForm.valid">
                        VERIFY
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

