// import {DatepickerCustomHeaderExample, ExampleHeader} from "./datapicker/datapicker-header";
// import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material";
// import {DatepickerCustomHeaderExample, ExampleHeader} from "./datapicker-header/datapicker-header";
// import {DemoMaterialModule} from "../material.module";
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
export { SharedModule };
export { ɵ0 };
