import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";
import {PARTNERS_DOMAINS} from "../config/project";

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  public menuChanged = new BehaviorSubject(false);
  private storage = sessionStorage;

  constructor() {
  }

  isRiverPay() {
      return false;
    // return true;
    //return window.location.hostname.includes(PARTNERS_DOMAINS.riverPay);
  }

}
