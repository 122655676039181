import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-financial-info-item',
    templateUrl: './financial-info-item.component.html',
    styleUrls: ['./financial-info-item.component.scss']
})
export class FinancialInfoItemComponent implements OnInit {

    @Input() value;
    @Input() negativeIsNotDashed;

    constructor() {
    }

    ngOnInit() {

    }

    isNegativeNumber(amount) {
        return amount < 0;
    }

    getAbs(amount) {
        return Math.abs(amount);
    }

}
