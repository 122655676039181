import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {ActivatedRoute} from "@angular/router";
import {ExampleHeader} from "../../shared/datapicker-header/datapicker-header";
import {DirectDepositService} from "../../service/direct-deposit/directDeposit.service";

@Component({
    selector: 'app-direct-deposit',
    templateUrl: './direct-deposit.component.html',
    styleUrls: ['./direct-deposit.component.scss'],
    // animations: [routerTransition('left')],
    // host: {'[@routerTransition]': ''}
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class DirectDepositComponent implements OnInit {
    depositForm: FormGroup;
    exampleHeader = ExampleHeader;
    isAccountNumberError = false;
    constructor(private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private directDepositService: DirectDepositService,
                private route: ActivatedRoute,
                private popupComponent: PopupComponent) {

    }

    ngOnInit() {
        this.depositForm = this.formBuilder.group({
            firstName: [null, Validators.required],
            lastName: [null, Validators.required],
            bankName: [null, Validators.required],
            routingNumber: [null, Validators.required],
            accountNumber: [null, Validators.required],
            reEnterAccountNumber: [null, Validators.required],
            address: [null, Validators.required],
            city: [null, Validators.required],
            state: [null, Validators.required],
            zip: [null, Validators.required],
            birth: [null, Validators.required],
            ssn: [null],
        });
    }

    onSubmit() {
        const firstName = this.depositForm.controls.firstName.value;
        const lastName = this.depositForm.controls.lastName.value;
        const bankName = this.depositForm.controls.bankName.value;
        const routingNumber = this.depositForm.controls.routingNumber.value;
        const accountNumber = this.depositForm.controls.accountNumber.value;
        const reEnterAccountNumber = this.depositForm.controls.reEnterAccountNumber.value;
        const address = this.depositForm.controls.address.value;
        const city = this.depositForm.controls.city.value;
        const state = this.depositForm.controls.state.value;
        const zip = this.depositForm.controls.zip.value;
        const birth = (<HTMLInputElement>document.querySelector('[formcontrolname=birth]')).value;
        const ssn = this.depositForm.controls.ssn.value;

        this.loaderService.show();
        this.directDepositService.deposit(firstName, lastName, bankName, routingNumber, accountNumber, reEnterAccountNumber, address, city, state, zip, birth, ssn).subscribe(data => {
            if(data.status){
                this.popupComponent.showPopupSuccess({text:data.message});
                this.depositForm.reset();
            } else {
                this.popupComponent.showPopupError({text:data.message});
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    getBackLink() {
        return localStorage.getItem('directDepositBackLink');
    }

    onReEnterAccountNumber($event) {
        if (this.depositForm.controls.reEnterAccountNumber.value && this.depositForm.controls.accountNumber.value) {
            if (this.depositForm.controls.reEnterAccountNumber.value !== this.depositForm.controls.accountNumber.value) {
                this.isAccountNumberError = true;
            } else {
                this.isAccountNumberError = false;
            }
        } else {
            this.isAccountNumberError = false;
        }

    }

}
