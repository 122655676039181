export class ReportTransactions {
  items = [];
  transformItems = [];
  constructor(items?: any) {
    this.items = items;
    this.init();
  }

  init() {
    if (this.items) {
      this.items.forEach(el => {
        this.transformItems.push(new ReportTransactionsItem(el));
      });
    }
  }
}


export class ReportTransactionsItem {
  id;
  date;
  event;
  value;
  balance;
  reverse;
  constructor(item: any) {
    this.id = item.id;
    this.date = item.date;
    this.event = item.event;
    this.value = item.value;
    this.balance = item.balance;
    this.reverse = item.reverse;
  }

  public updateReverse(value: boolean) {
    this.reverse = value;
  }

}
