var UserInfo = /** @class */ (function () {
    function UserInfo(data) {
        if (data) {
            this._phone = data.phone;
            this._pin = data.accountId;
            this._id = data.id;
            this._debit = data.debit;
            this._credit = data.credit;
            this._totalDebit = data.totalDebit;
            this._totalCredit = data.totalCredit;
            this._totalCrypto = data.totalCrypto;
        }
    }
    Object.defineProperty(UserInfo.prototype, "phone", {
        get: function () {
            return this._phone;
        },
        set: function (value) {
            this._phone = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserInfo.prototype, "pin", {
        get: function () {
            return this._pin;
        },
        set: function (value) {
            this._pin = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserInfo.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (value) {
            this._id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserInfo.prototype, "debit", {
        get: function () {
            return this._debit;
        },
        set: function (value) {
            this._debit = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserInfo.prototype, "credit", {
        get: function () {
            return this._credit;
        },
        set: function (value) {
            this._credit = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserInfo.prototype, "totalDebit", {
        get: function () {
            return this._totalDebit;
        },
        set: function (value) {
            this._totalDebit = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserInfo.prototype, "totalCredit", {
        get: function () {
            return this._totalCredit;
        },
        set: function (value) {
            this._totalCredit = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserInfo.prototype, "totalCrypto", {
        get: function () {
            return this._totalCrypto;
        },
        set: function (value) {
            this._totalCrypto = value;
        },
        enumerable: true,
        configurable: true
    });
    return UserInfo;
}());
export { UserInfo };
