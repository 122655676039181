import {Injectable} from '@angular/core';
import {DIRECT_DEPOSIT_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class DirectDepositService {
    private config = DIRECT_DEPOSIT_CONFIG;

    constructor(private http: HttpClient) {}

    deposit(firstName, lastName, bankName, routingNumber, accountNumber, reEnterAccountNumber, address, city, state, zip, birth, ssn) {
        return this.http.post<any>(this.config.deposit, {firstName, lastName, bankName, routingNumber, accountNumber, reEnterAccountNumber, address, city, state, zip, birth, ssn});
    };
};
