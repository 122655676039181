import { DIRECT_DEPOSIT_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DirectDepositService = /** @class */ (function () {
    function DirectDepositService(http) {
        this.http = http;
        this.config = DIRECT_DEPOSIT_CONFIG;
    }
    DirectDepositService.prototype.deposit = function (firstName, lastName, bankName, routingNumber, accountNumber, reEnterAccountNumber, address, city, state, zip, birth, ssn) {
        return this.http.post(this.config.deposit, { firstName: firstName, lastName: lastName, bankName: bankName, routingNumber: routingNumber, accountNumber: accountNumber, reEnterAccountNumber: reEnterAccountNumber, address: address, city: city, state: state, zip: zip, birth: birth, ssn: ssn });
    };
    ;
    DirectDepositService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DirectDepositService_Factory() { return new DirectDepositService(i0.ɵɵinject(i1.HttpClient)); }, token: DirectDepositService, providedIn: "root" });
    return DirectDepositService;
}());
export { DirectDepositService };
;
