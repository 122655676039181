var PaginationData = /** @class */ (function () {
    function PaginationData(name, selector, selectorParent, itemsListIfNoResult) {
        this._prevPagination = 0;
        this._previewPagination = 1;
        this._lastScrollTop = 0;
        this._dataRichEnd = false;
        this._items = [];
        this._limit = 0;
        this._loading = false;
        this._countLastAddedItems = 0;
        this._canScroll = true;
        this._countTotalItems = 0;
        this._lastAddedItem = 0;
        this.name = name;
        this.selector = selector;
        this.selectorParent = selectorParent;
        this.itemsListIfNoResult = itemsListIfNoResult ? itemsListIfNoResult : 'result';
    }
    Object.defineProperty(PaginationData.prototype, "prevPagination", {
        get: function () {
            return this._prevPagination;
        },
        set: function (prevPagination) {
            this._prevPagination = prevPagination;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginationData.prototype, "previewPagination", {
        get: function () {
            return this._previewPagination;
        },
        set: function (previewPagination) {
            this._previewPagination = previewPagination;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginationData.prototype, "lastScrollTop", {
        get: function () {
            return this._lastScrollTop;
        },
        set: function (lastScrollTop) {
            this._lastScrollTop = lastScrollTop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginationData.prototype, "dataRichEnd", {
        get: function () {
            return this._dataRichEnd;
        },
        set: function (dataRichEnd) {
            this._dataRichEnd = dataRichEnd;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginationData.prototype, "items", {
        get: function () {
            return this._items;
        },
        set: function (items) {
            this._items = items;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginationData.prototype, "limit", {
        get: function () {
            return this._limit;
        },
        set: function (limit) {
            this._limit = limit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginationData.prototype, "loading", {
        get: function () {
            return this._loading;
        },
        set: function (loading) {
            this._loading = loading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginationData.prototype, "countLastAddedItems", {
        get: function () {
            return this._countLastAddedItems;
        },
        set: function (countLastAddedItems) {
            this._countLastAddedItems = countLastAddedItems;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginationData.prototype, "canScroll", {
        get: function () {
            return this._canScroll;
        },
        set: function (canScroll) {
            this._canScroll = canScroll;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginationData.prototype, "countTotalItems", {
        get: function () {
            return this._countTotalItems;
        },
        set: function (countTotalItems) {
            this._countTotalItems = countTotalItems;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginationData.prototype, "lastAddedItem", {
        get: function () {
            return this._lastAddedItem;
        },
        set: function (lastAddedItem) {
            this._lastAddedItem = lastAddedItem;
        },
        enumerable: true,
        configurable: true
    });
    return PaginationData;
}());
export { PaginationData };
