import { EventEmitter, OnInit } from '@angular/core';
import * as i0 from "@angular/core";
var MoreInfoComponent = /** @class */ (function () {
    function MoreInfoComponent() {
        this.close = new EventEmitter();
    }
    MoreInfoComponent.prototype.ngOnInit = function () {
    };
    MoreInfoComponent.prototype.onClosePopup = function () {
        this.close.emit();
    };
    MoreInfoComponent.prototype.getTotal = function (array, key) {
        var total = 0;
        array.forEach(function (el) {
            total += +el[key];
        });
        return total;
    };
    MoreInfoComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MoreInfoComponent_Factory() { return new MoreInfoComponent(); }, token: MoreInfoComponent, providedIn: "root" });
    return MoreInfoComponent;
}());
export { MoreInfoComponent };
