import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class BlurMenuService {
    public menuBlurred = new BehaviorSubject(false);

    constructor() {

    }

    blur() {
        this.menuBlurred.next(true);
        document.body.classList.add('is-hidden');

    }

    focus() {
        this.menuBlurred.next(false);
        document.body.classList.remove('is-hidden');
    }

}
