/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./financial-info-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./financial-info-item.component";
var styles_FinancialInfoItemComponent = [i0.styles];
var RenderType_FinancialInfoItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FinancialInfoItemComponent, data: {} });
export { RenderType_FinancialInfoItemComponent as RenderType_FinancialInfoItemComponent };
function View_FinancialInfoItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[2, "is-negative", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isNegativeNumber(_co.value); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.getAbs(_co.value))); _ck(_v, 1, 0, currVal_1); }); }
function View_FinancialInfoItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "is-empty"], ["style", "text-align: center"]], null, null, null, null, null))], null, null); }
export function View_FinancialInfoItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinancialInfoItemComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinancialInfoItemComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.value || _co.negativeIsNotDashed); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.value && !_co.negativeIsNotDashed); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_FinancialInfoItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-financial-info-item", [], null, null, null, View_FinancialInfoItemComponent_0, RenderType_FinancialInfoItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.FinancialInfoItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FinancialInfoItemComponentNgFactory = i1.ɵccf("app-financial-info-item", i3.FinancialInfoItemComponent, View_FinancialInfoItemComponent_Host_0, { value: "value", negativeIsNotDashed: "negativeIsNotDashed" }, {}, []);
export { FinancialInfoItemComponentNgFactory as FinancialInfoItemComponentNgFactory };
