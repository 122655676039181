<div class="conclusion" [class.conclusion--sm]="type && type === 'sm'">
    <div class="conclusion__row" *ngFor="let item of data.list">
        <div class="conclusion__side" [innerHTML]="item.title"></div>
        <div class="conclusion__side">
            {{item.amount | currency}}
        </div>
    </div>

    <ng-container *ngIf="data.title !== null">
        <h2 class="conclusion__title">{{data.title}}</h2>
        <h2 *ngIf="!isString(data.result)" [class.is-negative]="isNegativeNumber(data.result)"
            class="conclusion__result">{{getAbs(data.result) | currency}}</h2>
        <h2 *ngIf="isString(data.result)"
            class="conclusion__result">{{data.result}}</h2>
    </ng-container>
</div>
