import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TEMPLATE_VERSION } from "../../config/template_version";
import { PARTNERS_INFO } from "../../config/project";
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../modules/auth/_services/authentication.service";
import * as i3 from "../../service/login.service";
import * as i4 from "../../service/menu.service";
import * as i5 from "@angular/router";
import * as i6 from "../../service/loader.service";
import * as i7 from "../../service/partner.service";
import * as i8 from "../../service/rememberUser.service";
import * as i9 from "../../shared/popup/popup.component";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, fb, authenticationService, loginService, menuService, router, auth, loaderService, partnerService, rememberUserService, popupComponent) {
        this.formBuilder = formBuilder;
        this.fb = fb;
        this.authenticationService = authenticationService;
        this.loginService = loginService;
        this.menuService = menuService;
        this.router = router;
        this.auth = auth;
        this.loaderService = loaderService;
        this.partnerService = partnerService;
        this.rememberUserService = rememberUserService;
        this.popupComponent = popupComponent;
        this.TEMPLATE_VERSION = TEMPLATE_VERSION;
        this.isRiverPay = this.partnerService.isRiverPay();
        this.partnerLogo = PARTNERS_INFO.default.logo;
        this.partnerName = PARTNERS_INFO.default.name;
    }
    // phoneFormActive = true;
    // phoneLastFour = '0005';
    // numOfDigits = 6;
    //
    // @ViewChildren('inputs') inputs: QueryList<ElementRef>;
    LoginComponent.prototype.clickInside = function (event) {
        var snakeBtns = document.querySelectorAll('.snake-btn');
        snakeBtns.forEach(function (el) {
            el.classList.remove('is-notouched');
        });
    };
    LoginComponent.prototype.ngOnInit = function () {
        this.auth.logout();
        this.userForm = this.formBuilder.group({
            password: [null, Validators.required],
            user_name: [null, Validators.required],
            remember_user: [false]
        });
        this.userForm.controls.password.setValue('');
        if (localStorage.remember_user !== "false") {
            this.userForm.controls.remember_user.setValue(true);
            this.userForm.controls.user_name.setValue(localStorage.usrname);
        }
        else {
            this.userForm.controls.remember_user.setValue(false);
            //this.userForm.controls.password.setValue('');
            this.userForm.controls.user_name.setValue('');
        }
        this.rememberUserService.checkSaveUser(this.userForm.controls.user_name, this.userForm.controls.password);
        this.checkRiverPay();
    };
    LoginComponent.prototype.checkRiverPay = function () {
        if (this.isRiverPay) {
            this.partnerLogo = PARTNERS_INFO.riverPay.logo;
            this.partnerName = PARTNERS_INFO.riverPay.name;
        }
    };
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.userForm.invalid) {
            return;
        }
        var userName = this.userForm.controls.user_name.value;
        var password = this.userForm.controls.password.value;
        this.loaderService.show();
        this.authenticationService.login(userName, password).subscribe(function (data) {
            _this.loaderService.hide();
            try {
                if (data.status) {
                    if (_this.userForm.controls.remember_user.value) {
                        // save username and password
                        localStorage.usrname = userName;
                    }
                    else {
                        localStorage.usrname = '';
                    }
                    localStorage.remember_user = _this.userForm.controls.remember_user.value;
                    _this.rememberUserService.setSaveUser(userName, password);
                    _this.checkTemplate(data);
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
                _this.popupComponent.showPopupError({ text: 'Sorry there was an error try again later.' });
            }
        });
    };
    LoginComponent.prototype.checkTemplate = function (data) {
        var _this = this;
        //data.template = this.TEMPLATE_VERSION.version2;
        if (data.template && data.template === this.TEMPLATE_VERSION.version2) {
            localStorage.template = data.template;
            this.router.navigate(['/home/sell/existing-user']);
            return;
        }
        localStorage.removeItem('template');
        this.router.navigateByUrl('/home/sell', { skipLocationChange: true }).then(function () {
            _this.router.navigate(['/home/sell']);
        });
    };
    LoginComponent.prototype.onRememberUser = function (e) {
        var checked = e.target.checked;
        this.userForm.controls.remember_user.setValue(!!checked);
    };
    LoginComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginComponent_Factory() { return new LoginComponent(i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.LoginService), i0.ɵɵinject(i4.MenuService), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i6.LoaderService), i0.ɵɵinject(i7.PartnerService), i0.ɵɵinject(i8.RememberUserService), i0.ɵɵinject(i9.PopupComponent)); }, token: LoginComponent, providedIn: "root" });
    return LoginComponent;
}());
export { LoginComponent };
