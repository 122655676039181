import {Injectable} from '@angular/core';
import {FILL_REQUEST_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "../loader.service";

@Injectable({
  providedIn: 'root'
})
export class FillRequestService {
  private config = FILL_REQUEST_CONFIG;

  constructor(private http: HttpClient,
              private loaderService: LoaderService) {

  }

  fillRequest() {
    this.loaderService.show();
    return this.http.post<any>(this.config.request, {});
  }

  sendPin(pin) {
    this.loaderService.show();
    return this.http.post<any>(this.config.pin, {pin});
  }

  confirm(amount, pin) {
    this.loaderService.show();
    return this.http.post<any>(this.config.confirm, {amount, pin});
  }
}
