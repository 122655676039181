export class UserInfo {
    public _phone: string;
    private _pin: string;
    private _id: string;
    private _debit: any;
    private _credit: any;
    private _totalDebit: any;
    private _totalCredit: any;
    private _totalCrypto: any;

    constructor(data) {
        if(data){
            this._phone = data.phone;
            this._pin = data.accountId;
            this._id = data.id;
            this._debit = data.debit;
            this._credit = data.credit;
            this._totalDebit = data.totalDebit;
            this._totalCredit = data.totalCredit;
            this._totalCrypto = data.totalCrypto;
        }

    }

    get phone(): string {
        return this._phone;
    }

    set phone(value: string) {
        this._phone = value;
    }

    get pin(): string {
        return this._pin;
    }

    set pin(value: string) {
        this._pin = value;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }


    get debit(): any {
        return this._debit;
    }

    set debit(value: any) {
        this._debit = value;
    }

    get credit(): any {
        return this._credit;
    }

    set credit(value: any) {
        this._credit = value;
    }

    get totalDebit(): any {
        return this._totalDebit;
    }

    set totalDebit(value: any) {
        this._totalDebit = value;
    }

    get totalCredit(): any {
        return this._totalCredit;
    }

    set totalCredit(value: any) {
        this._totalCredit = value;
    }

    get totalCrypto(): any {
        return this._totalCrypto;
    }

    set totalCrypto(value: any) {
        this._totalCrypto = value;
    }
}
