import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HomeArticle} from "../../models/home-articles";
import {ActivityItem} from "../../models/activityItem";
import {PROJECT_CONFIG} from "../../config/project";
import {PhoneMaskService} from "../../service/phoneMask.service";

@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
    phoneMask = this.phoneMaskService.setPhoneMask();

    @Input() activity;
    @Output() openSettings: EventEmitter<any> = new EventEmitter();
    @Output() listScrolled: EventEmitter<any> = new EventEmitter();

    constructor(private phoneMaskService: PhoneMaskService,) {
    }

    ngOnInit() {

    }

    getPhone(item){
        const activityItem = new ActivityItem(item);
        return activityItem.phone;
    }

    getPin(item){
        const activityItem = new ActivityItem(item);
        return activityItem.pin;
    }

    getId(item){
        const activityItem = new ActivityItem(item);
        return activityItem.id;
    }

    listScroll($event) {
        this.listScrolled.emit($event);
    }

    openUserSettings(id) {
        this.openSettings.emit(id);
    }

    getPhoneMask(item){
        if(item.country_id){
            return this.phoneMaskService.getPhoneMaskByCountryId(item.country_id);
        }else{
            return this.phoneMaskService.getDefaultPhoneMask();
        }

    }

}
