import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { STRIPE_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var StripeService = /** @class */ (function () {
    function StripeService(ngZone, http) {
        this.ngZone = ngZone;
        this.http = http;
        this.visible = new BehaviorSubject(null);
        this.config = STRIPE_CONFIG;
    }
    StripeService.prototype.getPublishableKey = function () {
        return this.http.post(this.config.getPublishableKey, {});
    };
    StripeService.prototype.setPublishableKey = function (key) {
        //Stripe.setPublishableKey(key);
    };
    StripeService.prototype.createCardToken = function (number, cvc, expMonth, expYear, handler) {
        // Stripe.createToken({
        //     number: number,
        //     cvc: cvc,
        //     exp_month: expMonth,
        //     exp_year: expYear,
        // }, function(status, response) {
        //     handler(response);
        //
        //     //console.log("gggggg");
        //
        //     //console.log(response);
        //     if (response.error) {
        //         // var settingsError = {
        //         //     title:'Error',
        //         //     text:response.error.message,
        //         //     type:'error'
        //         // }
        //         // settings.errorFunction(settingsError);
        //     }
        //     else {
        //         // var request = $.ajax ({
        //         //     type: "POST",
        //         //     url: settings.cahgeCardController,
        //         //     dataType: "json",
        //         //     data: {
        //         //         "stripeToken" : response.id
        //         //     }
        //         // });
        //         //
        //         // request.done(function(objStripe){
        //         //     if(objStripe.status == true){
        //         //         var settingsFunction = {
        //         //             stripeData:objStripe.data
        //         //         };
        //         //         settings.requestDoneFunction(settingsFunction);
        //         //     }else{
        //         //         var settingsError = {
        //         //             title:'Error',
        //         //             text:objStripe.message,
        //         //             type:'error'
        //         //         }
        //         //         settings.errorFunction(settingsError);
        //         //     }
        //         // });
        //         //
        //         // request.fail(function(jqXHR, textStatus) {
        //         //     var settingsError = {
        //         //         title:'Error',
        //         //         text:"Error: failed to call Controller to process the transaction.",
        //         //         type:'error'
        //         //     }
        //         //     settings.errorFunction(settingsError);
        //         // });
        //     }
        // });
    };
    StripeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StripeService_Factory() { return new StripeService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.HttpClient)); }, token: StripeService, providedIn: "root" });
    return StripeService;
}());
export { StripeService };
