import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var PartnerService = /** @class */ (function () {
    function PartnerService() {
        this.menuChanged = new BehaviorSubject(false);
        this.storage = sessionStorage;
    }
    PartnerService.prototype.isRiverPay = function () {
        return false;
        // return true;
        //return window.location.hostname.includes(PARTNERS_DOMAINS.riverPay);
    };
    PartnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PartnerService_Factory() { return new PartnerService(); }, token: PartnerService, providedIn: "root" });
    return PartnerService;
}());
export { PartnerService };
