<div class="sell">
    <div class="sell__inner">
        <div class="navigation">
            <a [routerLink]="['/home/sell/games-providers/new-user']" class="navigation__button button snake-btn is-notouched">
                NEW USER
                <svg><rect></rect></svg>
            </a>
            <a [routerLink]="['/home/sell/existing-user']" class="navigation__button navigation__button--secondary button snake-btn is-notouched">
                EXISTING USER
                <svg><rect></rect></svg>
            </a>

            <div class="navigation__bottom">
                <div class="navigation__info">Your POS Balance is {{balance | currency : 'USD' : 'symbol'}}</div>
                <button (click)="requestRefill()" class="navigation__button navigation__button--default button snake-btn is-notouched">
                    REQUEST REFILL
                    <svg><rect></rect></svg>
                </button>
            </div>
        </div>
    </div>
</div>

