import {Injectable} from '@angular/core';
import {TEMPLATE_VERSION} from "../config/template_version";

@Injectable({
    providedIn: 'root'
})
export class TemplateService {
    TEMPLATE_VERSION = TEMPLATE_VERSION;


    constructor() {

    }

    isTemplate2() {
        return localStorage.template === this.TEMPLATE_VERSION.version2;
    }

}
