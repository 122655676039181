<div class="more-info-popup is-active" [class.is-active]="showPopup">

    <div class="more-info-popup__inner">
        <button class="more-info-popup__close" (click)="onClosePopup()">
            <svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                <use xlink:href="../../../../assets/images/sprite.svg#close-sign"></use>
            </svg>
        </button>

        <div class="more-info-popup__table">
            <div class="more-info-popup__item" *ngFor="let item of items">
                <div class="more-info-popup__row more-info-popup__head">
                    <h2 class="more-info-popup__title">{{item.title}}</h2>

                    <div *ngIf="item.type === 'number'" class="more-info-popup__right">{{getTotal(item.list, 'amount') | number}}</div>
                    <div *ngIf="item.type === 'currency'" class="more-info-popup__right">{{getTotal(item.list, 'amount') | currency}}</div>
                </div>

                <div class="more-info-popup__list">
                    <div class="more-info-popup__row more-info-popup__list-item" *ngFor="let listItem of item.list">
                        <div class="more-info-popup__left">{{listItem.title}}</div>
                        <div *ngIf="item.type === 'number'" class="more-info-popup__right">{{listItem.amount | number}}</div>
                        <div *ngIf="item.type === 'currency'" class="more-info-popup__right">{{listItem.amount | currency}}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>
