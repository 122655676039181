<div class="page" [class.is-blurred]="showSettlePopup" (click)="hideDateSelection($event)">
<div class="page__inner">

        <div class="collections">
            <div class="collections__wrapper">
                <h1 class="collections__title">COLLECTIONS SUMMARY</h1>

                <div class="date-selection js-date-selection" [class.is-active]="showDatePicker">
                    <button (click)="onDateSelectionBtnClick()" class="date-selection__btn">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.5338 2.43848H7.46387V3.84468H16.5338V2.43848Z" fill="white"/>
                            <path d="M6.05727 3.14148H4.66699V3.68846H6.05727V3.14148Z" fill="white"/>
                            <path d="M19.3307 3.14148H17.9404V3.68846H19.3307V3.14148Z" fill="white"/>
                            <path d="M20.7372 2.43848V5.09479H16.5345V3.14155H7.46456V5.09479H3.26189V2.43848H0V6.79766H23.9991V2.43848H20.7372Z" fill="white"/>
                            <path d="M0 8.20386V24.0001H23.9991V8.20386H0ZM5.24512 21.3752H2.92163V19.969H5.24512V21.3752ZM5.24512 18.3285H2.92163V16.9223H5.24512V18.3285ZM5.24512 15.2817H2.92163V13.8755H5.24512V15.2817ZM9.20306 21.3752H6.88003V19.969H9.20306V21.3752ZM9.20306 18.3285H6.88003V16.9223H9.20306V18.3285ZM9.20306 15.2817H6.88003V13.8755H9.20306V15.2817ZM13.161 21.3752H10.838V19.969H13.161V21.3752ZM13.161 18.3285H10.838V16.9223H13.161V18.3285ZM13.161 15.2817H10.838V13.8755H13.161V15.2817ZM13.161 12.235H10.838V10.8288H13.161V12.235ZM17.119 21.3752H14.796V19.969H17.119V21.3752ZM17.119 18.3285H14.796V16.9223H17.119V18.3285ZM17.119 15.2817H14.796V13.8755H17.119V15.2817ZM17.119 12.235H14.796V10.8288H17.119V12.235ZM21.0774 18.3285H18.7539V16.9223H21.0774V18.3285ZM21.0774 15.2817H18.7539V13.8755H21.0774V15.2817ZM21.0774 12.235H18.7539V10.8288H21.0774V12.235Z" fill="white"/>
                            <path d="M6.05727 0H4.66699V3.68845H6.05727V0Z" fill="white"/>
                            <path d="M19.3307 0H17.9404V3.68845H19.3307V0Z" fill="white"/>
                        </svg>
                    </button>
                    <div class="date-selection__dropdown">
                        <form [formGroup]="dateForm" (ngSubmit)="getData()" class="form-simple">
                            <div class="date-selection__row form-simple__row">
                                <div class="form-simple__item">
                                    <mat-form-field appearance="outline">
                                        <input formControlName="from" readonly placeholder="From" matInput [matDatepicker]="picker">
                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                            <svg matDatepickerToggleIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.5338 2.43848H7.46387V3.84468H16.5338V2.43848Z" fill="white"/>
                                                <path d="M6.05727 3.14148H4.66699V3.68846H6.05727V3.14148Z" fill="white"/>
                                                <path d="M19.3307 3.14148H17.9404V3.68846H19.3307V3.14148Z" fill="white"/>
                                                <path d="M20.7372 2.43848V5.09479H16.5345V3.14155H7.46456V5.09479H3.26189V2.43848H0V6.79766H23.9991V2.43848H20.7372Z" fill="white"/>
                                                <path d="M0 8.20386V24.0001H23.9991V8.20386H0ZM5.24512 21.3752H2.92163V19.969H5.24512V21.3752ZM5.24512 18.3285H2.92163V16.9223H5.24512V18.3285ZM5.24512 15.2817H2.92163V13.8755H5.24512V15.2817ZM9.20306 21.3752H6.88003V19.969H9.20306V21.3752ZM9.20306 18.3285H6.88003V16.9223H9.20306V18.3285ZM9.20306 15.2817H6.88003V13.8755H9.20306V15.2817ZM13.161 21.3752H10.838V19.969H13.161V21.3752ZM13.161 18.3285H10.838V16.9223H13.161V18.3285ZM13.161 15.2817H10.838V13.8755H13.161V15.2817ZM13.161 12.235H10.838V10.8288H13.161V12.235ZM17.119 21.3752H14.796V19.969H17.119V21.3752ZM17.119 18.3285H14.796V16.9223H17.119V18.3285ZM17.119 15.2817H14.796V13.8755H17.119V15.2817ZM17.119 12.235H14.796V10.8288H17.119V12.235ZM21.0774 18.3285H18.7539V16.9223H21.0774V18.3285ZM21.0774 15.2817H18.7539V13.8755H21.0774V15.2817ZM21.0774 12.235H18.7539V10.8288H21.0774V12.235Z" fill="white"/>
                                                <path d="M6.05727 0H4.66699V3.68845H6.05727V0Z" fill="white"/>
                                                <path d="M19.3307 0H17.9404V3.68845H19.3307V0Z" fill="white"/>
                                            </svg>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="form-simple__item">
                                    <mat-form-field appearance="outline">
                                        <input formControlName="to" readonly placeholder="To" matInput [matDatepicker]="pickerTo">
                                        <mat-datepicker-toggle matSuffix [for]="pickerTo">
                                            <svg matDatepickerToggleIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.5338 2.43848H7.46387V3.84468H16.5338V2.43848Z" fill="white"/>
                                                <path d="M6.05727 3.14148H4.66699V3.68846H6.05727V3.14148Z" fill="white"/>
                                                <path d="M19.3307 3.14148H17.9404V3.68846H19.3307V3.14148Z" fill="white"/>
                                                <path d="M20.7372 2.43848V5.09479H16.5345V3.14155H7.46456V5.09479H3.26189V2.43848H0V6.79766H23.9991V2.43848H20.7372Z" fill="white"/>
                                                <path d="M0 8.20386V24.0001H23.9991V8.20386H0ZM5.24512 21.3752H2.92163V19.969H5.24512V21.3752ZM5.24512 18.3285H2.92163V16.9223H5.24512V18.3285ZM5.24512 15.2817H2.92163V13.8755H5.24512V15.2817ZM9.20306 21.3752H6.88003V19.969H9.20306V21.3752ZM9.20306 18.3285H6.88003V16.9223H9.20306V18.3285ZM9.20306 15.2817H6.88003V13.8755H9.20306V15.2817ZM13.161 21.3752H10.838V19.969H13.161V21.3752ZM13.161 18.3285H10.838V16.9223H13.161V18.3285ZM13.161 15.2817H10.838V13.8755H13.161V15.2817ZM13.161 12.235H10.838V10.8288H13.161V12.235ZM17.119 21.3752H14.796V19.969H17.119V21.3752ZM17.119 18.3285H14.796V16.9223H17.119V18.3285ZM17.119 15.2817H14.796V13.8755H17.119V15.2817ZM17.119 12.235H14.796V10.8288H17.119V12.235ZM21.0774 18.3285H18.7539V16.9223H21.0774V18.3285ZM21.0774 15.2817H18.7539V13.8755H21.0774V15.2817ZM21.0774 12.235H18.7539V10.8288H21.0774V12.235Z" fill="white"/>
                                                <path d="M6.05727 0H4.66699V3.68845H6.05727V0Z" fill="white"/>
                                                <path d="M19.3307 0H17.9404V3.68845H19.3307V0Z" fill="white"/>
                                            </svg>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #pickerTo></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>



                            <div class="form-simple__btns">
                                <button type="submit"
                                        class="button snake-btn is-notouched">
                                    Filter
                                    <svg><rect></rect></svg>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="collections__table">
                <table class="summary-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>IN</th>
                            <th>OUT</th>
                            <th>FEES</th>
                            <th>CASH OH</th>
                            <th>REMOTE OH</th>
                            <th>NET</th>
                            <th>LOCATION</th>
                            <th>VENDOR</th>
                            <th>SETTLEMENT</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let week of weeks; let last = last" [class.js-last-week]="last">
                            <td>{{week.date | date: 'MM/dd/yyyy'}}</td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="week.in"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="week.out"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="week.fees"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="week.cash_hand"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="week.remote_hand"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="checkNetIsPositive(week.net)"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="week.location"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="week.vendor"></app-financial-info-item>
                            </td>
                            <td class="td--colored" [class.td--settled]="week.status">
                                <app-financial-info-item [value]="checkNetIsPositive(week.net, week.settlement)"></app-financial-info-item>
                            </td>
                            <td>
                                <label class="summary-checkbox"
                                       [class.is-settled]="week.status"
                                       [class.is-disabled]="isSettleDisabled(week.date, week.net)">
                                    <input #weeksInputs
                                           class="summary-checkbox__input"
                                           type="checkbox"
                                           [attr.data-week-id]="week.id">
                                    <svg class="summary-checkbox__icon" width="18px" height="14px">
                                        <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#checked'"></use>
                                    </svg>
                                </label>
                            </td>
                        </tr>
                    </tbody>

                    <tfoot>
                        <tr>
                            <td>ALL TIME</td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="allTime.in"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="allTime.out"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="allTime.fees"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="allTime.cash_hand"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="allTime.remote_hand"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="checkNetIsPositive(allTime.net)"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="allTime.location"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [negativeIsNotDashed]="true" [value]="allTime.vendor"></app-financial-info-item>
                            </td>
                            <td>
                                <app-financial-info-item [value]="setAllTimeSettlement(allTime.net, allTime.settlement)"></app-financial-info-item>
                            </td>
                        </tr>
                    </tfoot>
                </table>

            </div>
            <div class="collections__settle-wrapper">
                <button type="submit"
                        [disabled]="isSettleSubmitDisabled()"
                        [class.is-disabled]="isSettleSubmitDisabled()"
                        [class.is-settled]="isAllSettled()"
                        (click)="onSubmit()"
                        class="button snake-btn is-notouched">
                    SETTLE
                    <svg><rect></rect></svg>
                </button>
            </div>
        </div>
    </div>
</div>

<app-pin-popup [showPopup]="showSettlePopup"
               [popupType]="settlePopupType"
               [data]="settlePopupData"
                (close)="onSettlePopupClose($event)"></app-pin-popup>
