import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ResetPageScrollService {
    // public visible = new BehaviorSubject(false);

    constructor() {

}

    onTouch(e) {
        var elem = e.target;
        var parents = this.getParents(elem);

        let test = false;
        parents.forEach(el => {
            if (el.classList.contains('page__body')) {
                test = true;
            }
        });

        if (!test && (<HTMLElement>document.querySelectorAll('.page')[0]).style.overflowY === 'hidden') {
            (<HTMLElement>document.querySelectorAll('.page')[0]).style.overflowY = 'auto';
        }

    }

    private getParents = function (elem) {
        // Set up a parent array
        var parents = [];

        // Push each parent element to the array
        for ( ; elem && elem !== document; elem = elem.parentNode ) {
            parents.push(elem);
        }

        // Return our parent array
        return parents;
    };
}
