<div class="page" [ngClass]="isNewUser ? 'page--primary' : 'page--secondary'">
    <div class="page__inner">
        <a [routerLink]="getBackLink()" class="page__back">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">game Provider</h1>

        <div class="page__body">

            <div class="games-selection">
                <button class="games-selection__close"></button>
                <div class="games-selection__inner" #scrolledEl>
                    <div class="games-selection__list">
                        <div class="games-selection__item" *ngFor="let game of games">
                            <img (click)="chooseGame(game)" [src]="game.image_link" alt="" class="games-selection__img">

                            <div class="games-selection__content">
                                <div class="games-selection__description" [innerHTML]="game.description"></div>
                                <button (click)="chooseGame(game)" class="games-selection__btn">SELECT</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
