export class ActivityItem {
    public _phone: string;
    private _pin: string;
    private _id: string;

    constructor(data) {
        if(data){
            this._phone = data.phone;
            this._pin = data.pin;
            this._id = data.id;
        }

    }

    get phone(): string {
        return this._phone;
    }

    set phone(value: string) {
        this._phone = value;
    }

    get pin(): string {
        return this._pin;
    }

    set pin(value: string) {
        this._pin = value;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }
}
