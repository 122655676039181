import * as i0 from "@angular/core";
var ResetPageScrollService = /** @class */ (function () {
    // public visible = new BehaviorSubject(false);
    function ResetPageScrollService() {
        this.getParents = function (elem) {
            // Set up a parent array
            var parents = [];
            // Push each parent element to the array
            for (; elem && elem !== document; elem = elem.parentNode) {
                parents.push(elem);
            }
            // Return our parent array
            return parents;
        };
    }
    ResetPageScrollService.prototype.onTouch = function (e) {
        var elem = e.target;
        var parents = this.getParents(elem);
        var test = false;
        parents.forEach(function (el) {
            if (el.classList.contains('page__body')) {
                test = true;
            }
        });
        if (!test && document.querySelectorAll('.page')[0].style.overflowY === 'hidden') {
            document.querySelectorAll('.page')[0].style.overflowY = 'auto';
        }
    };
    ResetPageScrollService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResetPageScrollService_Factory() { return new ResetPageScrollService(); }, token: ResetPageScrollService, providedIn: "root" });
    return ResetPageScrollService;
}());
export { ResetPageScrollService };
