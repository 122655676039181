import { EventEmitter, OnInit, ElementRef } from '@angular/core';
import { PAYMENTS_INFO_POPUP_TYPE } from "./payments-info-popup-config";
import * as i0 from "@angular/core";
var PaymentsInfoPopupComponent = /** @class */ (function () {
    function PaymentsInfoPopupComponent() {
        this.close = new EventEmitter();
        this.PAYMENTS_INFO_POPUP_TYPE = PAYMENTS_INFO_POPUP_TYPE;
    }
    Object.defineProperty(PaymentsInfoPopupComponent.prototype, "showPopup", {
        get: function () {
            return this._showPopup;
        },
        set: function (value) {
            this._showPopup = value;
            if (value) {
                this.onPopupShow();
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    PaymentsInfoPopupComponent.prototype.ngOnInit = function () { };
    PaymentsInfoPopupComponent.prototype.onClosePopup = function () {
        this.close.emit();
    };
    PaymentsInfoPopupComponent.prototype.getTotal = function (array, key) {
        var total = 0;
        array.forEach(function (el) {
            total += +el[key];
        });
        return total;
    };
    PaymentsInfoPopupComponent.prototype.isNegativeNumber = function (amount) {
        return amount < 0;
    };
    PaymentsInfoPopupComponent.prototype.getAbs = function (amount) {
        return Math.abs(amount);
    };
    PaymentsInfoPopupComponent.prototype.getTotalFromObject = function (obj, keyInner) {
        var total = 0;
        for (var key in obj) {
            if (key == 'fees') {
                total -= Number(obj[key][keyInner]);
            }
            else {
                total += Number(obj[key][keyInner]);
            }
        }
        return total;
    };
    PaymentsInfoPopupComponent.prototype.onPopupShow = function () {
        this.snakeBtn.nativeElement.classList.add('is-notouched');
        this.selectedMonMonth = this.period.from.split('/')[0];
        this.selectedMonDay = this.period.from.split('/')[1];
        this.selectedSunMonth = this.period.to.split('/')[0];
        this.selectedSunDay = this.period.to.split('/')[1];
        var from = this.period.from;
        var to = this.period.to;
        if (from === to) {
            this.selectedDay = this.period.from.split('/')[1];
            this.selectedMonth = this.period.from.split('/')[0];
            this.selectedDate = new Date(this.period.from);
            this.selectedDayName = this.getDateName(this.selectedDate.getDay());
        }
    };
    PaymentsInfoPopupComponent.prototype.showWeeklyDetails = function () {
        this.close.emit({
            status: true,
            type: this.PAYMENTS_INFO_POPUP_TYPE.weekly,
        });
    };
    PaymentsInfoPopupComponent.prototype.showDailyDetails = function () {
        this.close.emit({
            status: true,
            type: this.PAYMENTS_INFO_POPUP_TYPE.daily,
        });
    };
    PaymentsInfoPopupComponent.prototype.getDateName = function (index) {
        switch (index) {
            case 0: {
                return 'Sunday';
            }
            case 1: {
                return 'Monday';
            }
            case 2: {
                return 'Tuesday';
            }
            case 3: {
                return 'Wednesday';
            }
            case 4: {
                return 'Thursday';
            }
            case 5: {
                return 'Friday';
            }
            case 6: {
                return 'Saturday';
            }
        }
    };
    PaymentsInfoPopupComponent.prototype.changeDay = function (next) {
        var diff;
        if (next) {
            diff = +1;
        }
        else {
            diff = -1;
        }
        var newDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), (this.selectedDate.getDate() + diff));
        var newDayFormat = newDay.getMonth() + 1 + "/" + newDay.getDate() + "/" + newDay.getFullYear();
        this.close.emit({
            status: true,
            type: this.PAYMENTS_INFO_POPUP_TYPE.daily,
            dateFormat: newDayFormat,
            date: newDay
        });
    };
    PaymentsInfoPopupComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentsInfoPopupComponent_Factory() { return new PaymentsInfoPopupComponent(); }, token: PaymentsInfoPopupComponent, providedIn: "root" });
    return PaymentsInfoPopupComponent;
}());
export { PaymentsInfoPopupComponent };
